import React from 'react';
import Attachments from "./Attachments";
import TextAreaWithButtons from "./TextAreaWithButtons";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux";

const MessagesFooter: React.FC = () => {
    const attachments = useSelector((state: RootState) => state.inbox.attachments);

    return (
        <div className="min-w-56">
            {attachments.length > 0 ? (
                <div className="mx-3 mb-1">
                    <Attachments/>
                </div>) : null}
            <div className="mx-3 mb-3">
                <TextAreaWithButtons/>
            </div>
        </div>
    );
};

export default MessagesFooter;
