import React from 'react';
import {useDispatch} from 'react-redux';
import {setConnection} from '../../redux/slices/connections-slice';
import {Connection, ModalType} from "../types/types";
import {AppDispatch} from "../../redux";
import {setTypeOfModalToBeShown} from "../../redux/slices/common-slice";
import Button, {ButtonColor, ButtonSize} from "./Button";
import {FaRotate} from "react-icons/fa6";

interface OwnProps {
    connection: Connection;
}

const ForceSyncButton: React.FC<OwnProps> = ({connection}) => {
    const dispatch: AppDispatch = useDispatch();

    const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        dispatch(setTypeOfModalToBeShown(ModalType.ForceSyncContacts))
        dispatch(setConnection(connection));
    };

    return (
        <Button onClick={handleButtonClick} icon={<FaRotate/>} buttonColor={ButtonColor.TRANSPARENT}
                buttonSize={ButtonSize.NORMAL}/>
    );
};

export default ForceSyncButton;
