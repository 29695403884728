import React, {useCallback, useEffect, useRef} from 'react';
import Chat from './Chat';
import {useSelector} from 'react-redux';
import {RootState} from '../../../redux';
import {useFetchChats} from "../../../hooks/useFetchChats";

const Chats: React.FC = () => {
    const {page, setPage, totalPages, loading, fetchChats} = useFetchChats();
    const chats = useSelector((state: RootState) => state.inbox.chats || []);
    const observer = useRef<IntersectionObserver | null>(null);

    // Первый эффект для загрузки чатов при монтировании компонента
    useEffect(() => {
        fetchChats(); // Загрузка чатов при первой загрузке компонента
        // eslint-disable-next-line
    }, []);

    const handleObserver = useCallback((entries: IntersectionObserverEntry[]) => {
        const target = entries[0];
        if (target.isIntersecting && !loading && page < totalPages - 1) {
            setPage(page + 1);
        }
    }, [loading, page, totalPages, setPage]);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0
        };

        observer.current = new IntersectionObserver(handleObserver, options);
        const target = document.querySelector('#load-more-trigger');
        if (target) {
            observer.current.observe(target);
        }

        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, [handleObserver]);

    return (
        <div className="flex-1 overflow-y-auto overflow-hidden">
            {chats.map(chat => (
                <Chat key={chat.chatId} chat={chat}/>
            ))}
            {loading && <div className="text-center p-4 animate-pulse">Loading...</div>}
            {page < totalPages - 1 && (
                <span id="load-more-trigger" className="h-1"> </span>
            )}
        </div>
    );
};

export default Chats;
