import React, {useEffect} from 'react';

const ComingSoon: React.FC = () => {
    useEffect(() => {
        // CSS для крутящейся шестеренки
        const styles = `
            .spinner {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100px;
                height: 100px;
                position: relative;
            }

            .gear {
                width: 60px;
                height: 60px;
                border: 10px solid #ffc107;
                border-top: 10px solid transparent;
                border-radius: 50%;
                animation: spin 2s linear infinite;
            }

            @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
            }
        `;

        // Встроить стили в HTML-документ
        const styleSheet = document.createElement("style");
        styleSheet.innerText = styles;
        document.head.appendChild(styleSheet);

        // Удалить стили при размонтировании компонента
        return () => {
            document.head.removeChild(styleSheet);
        };
    }, []);

    return (
        <div className="flex flex-col items-center justify-center h-full">
            <div className="spinner">
                <div className="gear"></div>
            </div>
            <p className="text-center mt-4 text-lg font-semibold text-slate-700">Раздел находится в разработке...</p>
        </div>
    );
};

export default ComingSoon;
