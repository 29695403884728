import instance from './base-api';
import {AuthLoginRequest, AuthSignupRequest} from "../components/types/types";

const apiUrl = '/auth-ws';

// Password Request Resource
const authWsPasswordRequest = {
    getPasswordRequest: (token: string) => instance.get(`${apiUrl}/public/password/${token}`),
    updatePasswordRequest: (token: string, passwordData: {
        password: string
    }) => instance.put(`${apiUrl}/public/password/${token}`, passwordData),
    forgotPassword: (email: string) => instance.post(`${apiUrl}/public/password/forgot`, {email}),
};

// Sign Up Resource
const authWsSignup = {
    signUp: (authSignupRequest: AuthSignupRequest, inviteCode?: string) => instance.post(`${apiUrl}/public/sign-up${inviteCode ? `?inviteCode=${inviteCode}` : ''}`, authSignupRequest),
    signUpWeb: (authSignupRequest: AuthSignupRequest, inviteCode?: string) => instance.post(`${apiUrl}/public/sign-up/web${inviteCode ? `?inviteCode=${inviteCode}` : ''}`, authSignupRequest),
};

// Sign In Resource
const authWsSignin = {
    signIn: (authLoginRequest: AuthLoginRequest) => instance.post(`${apiUrl}/public/sign-in`, authLoginRequest),
    signInWeb: (authLoginRequest: AuthLoginRequest) => instance.post(`${apiUrl}/public/sign-in/web`, authLoginRequest),
};

// Logout Resource
const authWsLogout = {
    getLogout: () => instance.get(`${apiUrl}/public/logout`),
    postLogout: () => instance.post(`${apiUrl}/public/logout`, {}),
};

// Invitation Resource
const authWsInvitation = {
    validateInvite: (inviteCode: string) => {
        return instance.post(`${apiUrl}/public/invitation/validate/${inviteCode}`, {inviteCode: inviteCode});
    }
};


// VK Social Auth Resource
const authWsVkSocial = {
    getAuth: (platform: string) => instance.get(`${apiUrl}/public/social/vk/${platform}`),
    getRedirect: (platform: string) => instance.get(`${apiUrl}/public/social/vk/redirect/${platform}`),
    getApproval: (platform: string) => instance.get(`${apiUrl}/public/social/vk/approval/${platform}`),
};

// Google Social Auth Resource
const authWsGoogleSocial = {
    getAuth: (platform: string) => instance.get(`${apiUrl}/public/social/google/${platform}`),
    getRedirect: (platform: string) => instance.get(`${apiUrl}/public/social/google/redirect/${platform}`),
    getApproval: (platform: string) => instance.get(`${apiUrl}/public/social/google/approval/${platform}`),
};

// Экспортируем группированные функции
export {
    authWsPasswordRequest,
    authWsSignup,
    authWsSignin,
    authWsLogout,
    authWsInvitation,
    authWsVkSocial,
    authWsGoogleSocial
};
