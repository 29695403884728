import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {setNewConnectionId, setSelectedMessenger} from "../../../redux/slices/connections-slice";
import {ChannelType} from "../../types/types";
import ConnectionCard from "../../atom/ConnectionCard";
import {useNavigate} from "react-router-dom";
import ContentWrapper from "../../atom/ContentWrapper";
import {useHeader} from "../../../context/HeaderContext";

const AddConnection: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {setHeaderContent} = useHeader();

    useEffect(() => {
        dispatch(setSelectedMessenger(null));
        dispatch(setNewConnectionId(null));
    }, [dispatch]);

    useEffect(() => {
        setHeaderContent('Добавить подключение');
    }, [setHeaderContent]);

    const messengers = [
        {
            id: ChannelType.WHATSAPP,
            name: "WhatsApp",
            color: "whatsapp",
            description: "Подключите WhatsApp для общения с клиентами",
            icon: "fa-whatsapp",
        },
        {
            id: ChannelType.TELEGRAM,
            name: "Telegram",
            color: "telegram",
            description: "Подключите Telegram для общения с клиентами",
            icon: "fa-telegram",
        },
        {
            id: ChannelType.AVITO,
            name: "Avito",
            color: "avito",
            description: "Взаимодействуйте со своей аудиторией в Avito.",
            icon: "fa-avito",
        },
    ];

    const handleMessengerSelect = (channelType: ChannelType) => {
        dispatch(setSelectedMessenger(channelType));
        navigate(`/connections/add-connection/${channelType.toLowerCase()}`);
    };

    return (
        <ContentWrapper customClass="overflow-auto">
            <div className="p-5">
                <div className="flex gap-5 justify-center flex-wrap">
                    {messengers.map((messenger) => (
                        <ConnectionCard
                            key={messenger.id}
                            type={messenger.name}
                            textColor={`text-${messenger.color}`}
                            description={messenger.description}
                            icon={messenger.icon}
                            onClick={() => handleMessengerSelect(messenger.id)}
                        />
                    ))}
                </div>
            </div>
        </ContentWrapper>
    );
};

export default AddConnection;