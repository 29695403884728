import React from 'react';


const LostMessagesWarning: React.FC = () => {
    return (
        <p className="text-justify leading-snug">
            Возможно, что не все старые сообщения будут доступны. Но не беспокойтесь, все новые сообщения после
            синхронизации будут приходить без проблем.
        </p>
    );
};

export default LostMessagesWarning;