import React from 'react';
import FilterByConnection from "./FilterByConnection";
import FilterOnlyUnreadChats from "./FilterOnlyUnreadChats";

const ChatsToolbar: React.FC = () => {
    return (
        <div className="flex space-x-0.5 items-center justify-between h-16 border-b border-b-[#ddd] border-solid px-3">
            <FilterByConnection/>
            <FilterOnlyUnreadChats/>
        </div>
    );
};

export default ChatsToolbar;