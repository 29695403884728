import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../../../redux';
import {ChannelType, ModalType} from "../../types/types";
import {clientWsAvitoChannel, clientWsTelegramChannel, clientWsWhatsappChannel} from "../../../api/client-ws";
import {setTypeOfModalToBeShown} from "../../../redux/slices/common-slice";
import ModalWrapper from "../../atom/ModalWrapper";
import CheckboxField from "../../atom/ChekboxField";

const ConnectionsForceSyncContactsModal: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const connection = useSelector((state: RootState) => state.connections.connection);
    const typeOfModalToBeShown = useSelector((state: RootState) => state.common.typeOfModalToBeShown);
    const [isConfirmed, setIsConfirmed] = useState(false);

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsConfirmed(e.target.checked);
    };

    const onRequestClose = () => {
        dispatch(setTypeOfModalToBeShown(null));
    };

    const handleSubmit = async () => {
        if (isConfirmed && connection) {
            try {
                if (connection.channelType === ChannelType.WHATSAPP) {
                    await clientWsWhatsappChannel.syncContactsAndMessages(connection.channelId);
                } else if (connection.channelType === ChannelType.TELEGRAM) {
                    await clientWsTelegramChannel.syncContactsAndMessages(connection.channelId);
                } else if (connection.channelType === ChannelType.AVITO) {
                    await clientWsAvitoChannel.syncContactsAndMessages(connection.channelId);
                }
                onRequestClose();
            } catch (error) {
                console.error(`Ошибка при принудительной синхронизации канала ${connection.channelType}:`, error);
            }
        }
    };

    useEffect(() => {
        if (!connection) setIsConfirmed(false);
    }, [connection]);

    return connection ? (
        <ModalWrapper
            isOpen={typeOfModalToBeShown === ModalType.ForceSyncContacts}
            onRequestClose={onRequestClose}
            contentLabel="Ресинхронизация"
            badge={connection.channelType}
            okButtonText="Старт"
            cancelButtonText="Отмена"
            onOkButtonClick={handleSubmit}
            okButtonDisabled={!isConfirmed}
        >
            <div className="flex flex-col">
                <label className="text-slate-800 mb-5">
                    <p>
                        Принудительная синхронизация контактов может потребоваться, если по каким-то причинам
                        первичная синхронизация не может быть выполнена.
                    </p>
                </label>
                <label className="text-xs bg-blue-100 text-blue-600 p-2.5 rounded-lg mb-5">
                    ID подключения: {connection.channelId}
                </label>
                <CheckboxField
                    name="rememberMe"
                    checked={isConfirmed}
                    onChange={handleCheckboxChange}
                    label="Да, я действительно хочу принудительно синхронизировать чаты для выбранного подключения"
                />
            </div>
        </ModalWrapper>
    ) : null;
};

export default ConnectionsForceSyncContactsModal;
