import React from "react";
import {RoleType} from "../components/types/types";
import {useProfileHelper} from "../hooks/useProfileHelper";

interface RoleBasedRenderProps {
    allowedRoles: RoleType[];
    children: React.ReactNode;
}

const RoleBasedRender: React.FC<RoleBasedRenderProps> = ({allowedRoles, children}) => {
    const {getRole} = useProfileHelper();

    const userRole = getRole();

    return allowedRoles.includes(userRole) ? <>{children}</> : null;
};

export default RoleBasedRender