import React, {FC, MouseEvent, useEffect, useRef, useState} from 'react';
import Button, {ButtonColor, ButtonSize} from "../../atom/Button";
import {FaEllipsisV} from "react-icons/fa";

interface ConnectionsRowMenuProps {
    onEdit: () => void;
    onDelete: () => void;
}

const ConnectionsRowMenu: FC<ConnectionsRowMenuProps> = ({onEdit, onDelete}) => {
    const [open, setOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setOpen(!open);
    };

    const handleMenuClick = (e: MouseEvent<HTMLDivElement>, action: () => void) => {
        e.stopPropagation();
        setOpen(false);
        action();
    };

    const handleClickOutside = (e: Event) => {
        if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <div className="relative inline-block mr-1" ref={menuRef}>
            <Button onClick={handleButtonClick} icon={<FaEllipsisV/>} buttonColor={ButtonColor.TRANSPARENT}
                    buttonSize={ButtonSize.SMALL}/>
            {open && (
                <div className="absolute right-0 mt-1 bg-white shadow-md rounded-lg overflow-hidden z-10">
                    <div
                        className="px-3 py-2 cursor-pointer text-slate-800 hover:bg-slate-100"
                        onClick={(e) => handleMenuClick(e, onEdit)}
                    >
                        Редактировать
                    </div>
                    <div
                        className="px-3 py-2 cursor-pointer text-slate-800 hover:bg-slate-100"
                        onClick={(e) => handleMenuClick(e, onDelete)}
                    >
                        Удалить
                    </div>
                </div>
            )}
        </div>
    );
};

export default ConnectionsRowMenu;
