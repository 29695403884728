import React, {useEffect} from 'react';
import {useHeader} from '../../context/HeaderContext';
import ChatsToolbar from "../molecula/inbox/ChatsToolbar";
import Chats from "../molecula/inbox/Chats";
import ContentWrapper from "../atom/ContentWrapper";
import MessagesToolbar from "../molecula/inbox/MessagesToolbar";
import Messages from "../molecula/inbox/Messages";
import MessagesFooter from "../molecula/inbox/MessagesFooter";
import PollingInbox from "../molecula/inbox/PollingInbox";
import InboxSettingsModal from "../molecula/inbox/InboxSettingsModal";
import {clientWsUserProfile} from "../../api/client-ws";
import {AppDispatch} from "../../redux";
import {useDispatch} from "react-redux";
import {setUseEnterToSend} from "../../redux/slices/inbox-slice";

const Inbox: React.FC = () => {
    const {setHeaderContent} = useHeader();
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await clientWsUserProfile.load();
                dispatch(setUseEnterToSend(response.data.settings.useEnterToSend));
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };
        setHeaderContent('Сообщения');
        fetchUserProfile();
    }, [setHeaderContent, dispatch]);


    return (
        <>
            <PollingInbox/>
            <InboxSettingsModal/>
            <ContentWrapper>
                <div className="flex h-screen overflow-hidden">
                    <div className="flex flex-col flex-1 w-0">
                        <ChatsToolbar/>
                        <Chats/>
                    </div>
                    <div className="flex flex-2 flex-col shadow-lg">
                        <MessagesToolbar/>
                        <Messages/>
                        <MessagesFooter/>
                    </div>
                    {/*<div className="flex flex-col flex-1">*/}
                    {/*    <ChatEditorHeader/>*/}
                    {/*    <ChatMergeOptions/>*/}
                    {/*</div>*/}
                </div>
            </ContentWrapper>
        </>
    );
};

export default Inbox;
