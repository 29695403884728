import React from "react";

interface NetworkStyles {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    text: string;
    background: string;
    backgroundInverted: string;
    border: string;
    logo: React.ReactNode;
    hover: string;
    hoverInverted: string;
}

const networkStyles: { [key: string]: NetworkStyles } = {
    whatsapp: {
        text: 'text-whatsapp',
        hover: 'hover:bg-whatsapp-20',
        hoverInverted: 'hover:bg-whatsapp-20',
        background: 'bg-whatsapp',
        backgroundInverted: 'bg-whatsapp-10',
        border: 'border border-whatsapp-10',
        logo: <i className="fab fa-whatsapp"></i>,
    },
    telegram: {
        text: 'text-telegram',
        hover: 'hover:bg-telegram-20',
        hoverInverted: 'hover:bg-telegram-20',
        background: 'bg-telegram',
        backgroundInverted: 'bg-telegram-10',
        border: 'border border-telegram-10',
        logo: <i className="fab fa-telegram-plane"></i>,
    },
    avito: {
        text: 'text-avito',
        hover: 'hover:bg-avito-20',
        hoverInverted: 'hover:bg-avito-20',
        background: 'bg-avito',
        backgroundInverted: 'bg-avito-10',
        border: 'border border-avito-10',
        logo: <i className="fab fa-avito"></i>,
    },
    facebook: {
        text: 'text-facebook',
        hover: 'hover:bg-facebook-20',
        hoverInverted: 'hover:bg-facebook-20',
        background: 'bg-facebook',
        backgroundInverted: 'bg-facebook-10',
        border: 'border border-facebook-10',
        logo: <i className="fab fa-facebook-f"></i>,
    },
    instagram: {
        text: 'text-instagram',
        hover: 'hover:bg-instagram-20',
        hoverInverted: 'hover:bg-instagram-20',
        background: 'bg-instagram',
        backgroundInverted: 'bg-instagram-10',
        border: 'border border-instagram-10',
        logo: <i className="fab fa-instagram"></i>,
    },
    twitter: {
        text: 'text-twitter',
        hover: 'hover:bg-twitter-20',
        hoverInverted: 'hover:bg-twitter-20',
        background: 'bg-twitter',
        backgroundInverted: 'bg-twitter-10',
        border: 'border border-twitter-10',
        logo: <i className="fab fa-twitter"></i>,
    },
};

export const getNetworkStyles = (
    network: string,
    inverted: boolean,
    background?: boolean,
    border?: boolean,
    hover?: boolean
) => {
    const networkLowerCase = network.toLowerCase();

    const styles = networkStyles[networkLowerCase] || {
        text: 'text-slate-400',
        background: 'bg-slate-400',
        backgroundInverted: 'bg-slate-100',
        border: 'border-slate-100',
        hover: 'hover:bg-slate-300',
        hoverInverted: 'hover:bg-slate-200',
        logo: network.charAt(0).toUpperCase(),
    };

    const {
        text,
        background: bg,
        backgroundInverted,
        border: borderClass,
        logo,
        hoverInverted,
        hover: hoverClass,
    } = styles;

    let badgeClass = `${text}`;

    if (inverted) {
        if (background) {
            badgeClass += ` ${backgroundInverted}`;
        }
        if (border !== false) {
            badgeClass += ` ${borderClass}`;
        }
        if (hover !== false) {
            badgeClass += ` ${hoverInverted}`;
        }
    } else {
        if (background) {
            badgeClass += ` ${bg} text-white`;
        }
        if (border !== false) {
            badgeClass += ` ${borderClass}`;
        }
        if (hover !== false) {
            badgeClass += ` ${hoverClass}`;
        }
    }

    return {
        badgeClass,
        iconClass: logo,
    };
};
