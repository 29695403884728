const useLocalStorageService = () => {
    // Универсальная функция сохранения данных в localStorage
    const save = (key: string, value: string) => {
        localStorage.setItem(key, value);
    };

    // Универсальная функция обновления данных в localStorage
    const update = (key: string, newValue: string) => {
        if (localStorage.getItem(key)) {
            save(key, newValue);
        }
    };

    // Универсальная функция получения данных из localStorage
    const get = (key: string) => {
        return localStorage.getItem(key);
    };

    // Функция удаления данных из localStorage
    const remove = (key: string) => {
        localStorage.removeItem(key);
    };

    return {save, update, get, remove};
};

export default useLocalStorageService;