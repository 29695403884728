// FormWrapper.tsx
import React from 'react';

interface FormWrapperProps {
    children: React.ReactNode;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

const FormWrapper: React.FC<FormWrapperProps> = ({children, onSubmit}) => {
    return (
        <form onSubmit={onSubmit}
              className={"flex space-y-6 flex-col bg-white p-8 rounded-xl shadow-md min-w-fit w-full"}>
            {children}
        </form>
    );
};

export default FormWrapper;
