import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ModalType} from "../../components/types/types";

interface CommonState {
    typeOfModalToBeShown?: ModalType | null;
}

const initialState: CommonState = {
    typeOfModalToBeShown: null,
};

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setTypeOfModalToBeShown(state, action: PayloadAction<ModalType | null>) {
            state.typeOfModalToBeShown = action.payload;
        },
    },
});

export const {
    setTypeOfModalToBeShown,
} = commonSlice.actions;

export default commonSlice.reducer;
