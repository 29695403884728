import React from 'react';
import {Outlet} from 'react-router-dom';
import Header from '../../molecula/Header';
import Sidebar from '../../molecula/Sidebar';
import {HeaderProvider} from "../../../context/HeaderContext";

const BaseLayout: React.FC = () => {
    return (
        <HeaderProvider>
            <div className="flex h-screen">
                <Sidebar/>
                <div className="flex-1 flex flex-col">
                    <Header/>
                    <div className="flex-1 overflow-y-auto px-3 pt-1 pb-3">
                        <Outlet/>
                    </div>
                </div>
            </div>
        </HeaderProvider>
    );
};

export default BaseLayout;
