import React, {useEffect, useState} from 'react';
import {useHeader} from '../../context/HeaderContext';
import ContentWrapper from "../atom/ContentWrapper";
import {clientWsUserProfile} from "../../api/client-ws";
import {FaClock, FaEnvelope} from 'react-icons/fa';
import {FaPen} from "react-icons/fa6";

interface ProfileState {
    email: string;
    timezone: string;
    fullName: string;
    status: string;
}

const Profile: React.FC = () => {
    const {setHeaderContent} = useHeader();
    const [profile, setProfile] = useState<ProfileState>({
        email: "",
        timezone: "",
        fullName: "",
        status: ""
    });

    const [nameError, setNameError] = useState<string | null>(null);
    const [tempName, setTempName] = useState(profile.fullName);

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await clientWsUserProfile.load();
                setProfile(response.data);
                setTempName(response.data.fullName);
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };

        setHeaderContent('Профиль');
        fetchUserProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setHeaderContent]);

    const handleBlur = async (e: React.FormEvent<HTMLDivElement>) => {
        if (tempName.length > 50) {
            setNameError("Имя не должно превышать 50 символов");
            return;
        } else {
            setNameError(null);
        }

        if (tempName !== profile.fullName) {
            try {
                await clientWsUserProfile.updateUserProfileSettings({fullName: tempName});
                setProfile(prevProfile => ({...prevProfile, fullName: tempName}));
            } catch (error) {
                console.error('Error updating profile name:', error);
            }
        }
    };

    const handleInputChange = (e: React.FormEvent<HTMLDivElement>) => {
        const newName = e.currentTarget.textContent || '';

        setTempName(newName)

        if (newName.length > 50) {
            setNameError("Имя не должно превышать 50 символов");
        } else {
            setNameError(null);
        }
    };

    return (
        <ContentWrapper fullHeight={false}>
            <div className="flex flex-col items-center p-6 pb-10">
                <div className="relative group flex items-center">
                    <div
                        contentEditable
                        suppressContentEditableWarning
                        onInput={handleInputChange}
                        onBlur={handleBlur}
                        className="text-4xl font-bold text-center text-slate-800 bg-transparent z-10
                                focus:outline-none cursor-text"
                        style={{minWidth: '5ch'}} // Минимальная ширина
                    >
                        {profile.fullName}
                    </div>
                    <FaPen
                        className="absolute z-0 -right-6 -mb-1 text-slate-800 opacity-5 group-hover:opacity-100 group-focus-within:opacity-100 transition-opacity duration-300"
                    />
                </div>

                <p className={`text-red-500 text-sm transition-opacity duration-300 ${nameError ? 'opacity-100' : 'opacity-0'} ${nameError ? 'block' : 'hidden'}`}>
                    {nameError}
                </p>

                <p className="text-sm text-slate-500 mb-4">{profile.status}</p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full">
                    <div className="flex items-center">
                        <FaEnvelope className="text-blue-500 w-5 h-5 mr-2"/>
                        <span className="font-semibold">Email:&nbsp;</span> {profile.email}
                    </div>
                    <div className="flex items-center">
                        <FaClock className="text-yellow-500 w-5 h-5 mr-2"/>
                        <span className="font-semibold">Timezone:&nbsp;</span> {profile.timezone}
                    </div>
                </div>
            </div>
        </ContentWrapper>
    );
};

export default Profile;
