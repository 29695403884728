import React from 'react';

interface TelegramConnectionDescriptionProps {
    showAsList?: boolean;
}

const TelegramConnectionDescription: React.FC<TelegramConnectionDescriptionProps> = ({showAsList = false}) => {
    const content = (
        <>
            <li>Откройте Telegram на своём телефоне</li>
            <li>Перейдите в <strong>"Настройки"</strong> &gt; <strong>"Устройства"</strong> &gt; <strong>"Подключить
                устройство"</strong>
            </li>
            <li>Наведите камеру своего телефона на этот экран, чтобы считать QR-код</li>
        </>
    );

    return showAsList ? <ol className="list-decimal pl-5 space-y-2 text-lg">{content}</ol> : content;
};

export default TelegramConnectionDescription;
