import React, {useState} from 'react';
import {useAuth} from '../../context/AuthContext';
import {useHeader} from '../../context/HeaderContext';
import {FaBars, FaSignOutAlt} from 'react-icons/fa';
import {authWsLogout} from "../../api/auth-ws";
import {Helmet} from 'react-helmet';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../redux";
import Button, {ButtonColor, ButtonSize} from "../atom/Button";
import {setSidebarVisible} from "../../redux/slices/sidebar-slice";


const Header: React.FC = () => {
    const {headerContent} = useHeader();
    const dispatch: AppDispatch = useDispatch();
    const sidebarVisible = useSelector((state: RootState) => state.sidebar.sidebarVisible); // Redux sidebar

    const showSideBarButton = useSelector((state: RootState) => state.sidebar.sidebarButtonVisible);

    const auth = useAuth();
    const [loggingOut, setLoggingOut] = useState<boolean>(false);

    const toggleSidebar = () => {
        dispatch(setSidebarVisible(!sidebarVisible));
    };

    const handleLogoutClick = async () => {
        if (loggingOut) return;

        setLoggingOut(true);
        try {
            await authWsLogout.postLogout();
            auth.userLogout();
        } catch (error) {
            console.error('Logout failed', error);
        } finally {
            setLoggingOut(false);
        }
    };

    return (
        <header className="flex justify-between items-center px-3 h-20">
            <Helmet>
                <title>{headerContent} | ChatHolder</title>
            </Helmet>

            {!showSideBarButton ? null : (
                <Button icon={<FaBars/>} buttonSize={ButtonSize.NORMAL} onClick={toggleSidebar}
                        buttonColor={ButtonColor.TRANSPARENT}/>
            )}

            <h1 className={`font-medium text-slate-700 text-xl`}>{headerContent}</h1>

            <div className="flex">
                <Button buttonColor={ButtonColor.TRANSPARENT} icon={<FaSignOutAlt/>} buttonSize={ButtonSize.NORMAL}
                        onClick={handleLogoutClick} disabled={loggingOut}/>
            </div>
        </header>
    );
};

export default Header;
