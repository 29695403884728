import React, {useState} from 'react';
import {AttachmentResponse} from "../types/types";
import {FaFileAlt, FaFilePdf, FaTrash} from 'react-icons/fa';
import {AppDispatch} from "../../redux";
import {useDispatch} from "react-redux";
import {deleteAttachment} from "../../redux/slices/inbox-slice";

interface AttachmentProps {
    file: AttachmentResponse;
    url: string;
}

const Attachment: React.FC<AttachmentProps> = ({file, url}) => {
    const [isBrokenImage, setIsBrokenImage] = useState(false);
    const dispatch: AppDispatch = useDispatch();

    const handleImageError = () => {
        setIsBrokenImage(true);
    };

    const renderIcon = () => {
        if (file.contentType.startsWith('image/') && !isBrokenImage) {
            return (
                <img
                    src={url}
                    alt={file.name}
                    className={`w-7 h-7 object-cover rounded`}
                    onError={handleImageError}
                />
            );
        } else if (file.contentType === 'application/pdf') {
            return <FaFilePdf className="w-7 h-7 text-red-500"/>;
        } else {
            return <FaFileAlt className="w-7 h-7 text-slate-500"/>;
        }
    };

    const onDelete = (file: AttachmentResponse) => {
        dispatch(deleteAttachment(file.fileId));
    };

    return (
        <div className="relative inline-flex items-center space-x-2 group">
            <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-2"
                title={file.name}
            >
                <div
                    className="flex items-center justify-center bg-slate-100 rounded-full p-2 shadow-lg transition-transform duration-200 transform hover:scale-105 focus:outline-none"
                >
                    {renderIcon()}
                </div>
            </a>
            <button
                onClick={() => onDelete(file)}
                className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-white rounded-full p-1 shadow-md hover:bg-red-100 transition-all duration-300 opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto"
                title="Удалить файл"
            >
                <FaTrash className="w-4 h-4 text-red-500"/>
            </button>
        </div>
    );
};

export default Attachment;
