import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ChannelType, Connection} from "../../components/types/types";

interface ConnectionState {
    channelId: string | null;
    connection: Connection | null;
    selectedMessenger?: ChannelType | null;
    newConnectionId: string | null;
    connections: Connection[];
}

const initialState: ConnectionState = {
    channelId: null,
    connection: null,
    selectedMessenger: null,
    newConnectionId: null,
    connections: [],
};

const connectionsSlice = createSlice({
    name: 'connections',
    initialState,
    reducers: {
        setChannelId(state, action: PayloadAction<string | null>) {
            state.channelId = action.payload;
        },
        setConnection(state, action: PayloadAction<any>) {
            state.connection = action.payload;
        },
        setSelectedMessenger(state, action: PayloadAction<ChannelType | null>) {
            state.selectedMessenger = action.payload;
        },
        setNewConnectionId(state, action: PayloadAction<string | null>) {
            state.newConnectionId = action.payload;
        },
        setConnections(state, action: PayloadAction<Connection[]>) {
            state.connections = action.payload;
        },
        cleanConnections(state) {
            state.connections = [];
        },
        pushConnection(state, action: PayloadAction<Connection>) {
            state.connections = [...state.connections, action.payload];
        },
        updateConnection(state, action: PayloadAction<Connection>) {
            const index = state.connections.findIndex((conn) => conn.channelId === action.payload.channelId);
            if (index !== -1) {
                state.connections[index] = action.payload;
            }
        },
        deleteConnection(state, action: PayloadAction<string>) {
            state.connections = state.connections.filter((conn) => conn.channelId !== action.payload);
        },
    },
});

export const {
    setChannelId,
    setConnection,
    setSelectedMessenger,
    setNewConnectionId,
    setConnections,
    cleanConnections,
    pushConnection,
    updateConnection,
    deleteConnection,
} = connectionsSlice.actions;

export default connectionsSlice.reducer;
