export function formatDate(dateString: string): string {
    if (!dateString) {
        return ''; // Можно заменить на "Нет даты" или другой текст, если требуется
    }

    const date = new Date(dateString);
    const now = new Date();

    const isToday = date.toDateString() === now.toDateString();
    const isYesterday = new Date(now.getTime() - 86400000).toDateString() === date.toDateString();

    if (isToday) {
        return date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
    }

    if (isYesterday) {
        return 'Вчера';
    }

    const daysDifference = Math.floor((now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));

    if (daysDifference < 7) {
        const weekdays = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
        return weekdays[date.getDay()];
    }

    if (now.getFullYear() === date.getFullYear()) {
        return date.toLocaleDateString('ru-RU', {day: '2-digit', month: '2-digit'});
    }

    return date.toLocaleDateString('ru-RU', {day: '2-digit', month: '2-digit', year: 'numeric'});
}
