import axios, {AxiosError, AxiosRequestConfig} from 'axios';

// Определение типа для пользователя
interface User {
    accessToken: string;
}

// Определение типа для парсинга JWT токена
interface JwtPayload {
    exp: number;

    [key: string]: any; // Дополнительные поля, которые могут быть в JWT
}

// -- Вспомогательные функции

export function bearerAuth(user: User): string {
    return `Bearer ${user.accessToken}`;
}

export function parseJwt(token: string | null): JwtPayload | null {
    if (!token) {
        return null;
    }

    try {
        const base64Url = (token as string).split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
                .join('')
        );

        return JSON.parse(jsonPayload);
    } catch (error) {
        console.error("Invalid token", error);
        return null;
    }
}


export function handleLogError(error: AxiosError): void {
    if (error.response) {
        console.log(error.response.data);
    } else if (error.request) {
        console.log(error.request);
    } else {
        console.log(error.message);
    }
}

// -- Инициализация axios

const instance = axios.create({
    baseURL: '',
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    },
});

instance.interceptors.request.use(
    (config: AxiosRequestConfig): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
        // Проверка токена на истечение
        if (config.headers && typeof config.headers.Authorization === 'string') {
            const token = config.headers.Authorization.split(' ')[1];
            const data = parseJwt(token);
            if (data && Date.now() > data.exp * 1000) {
                window.location.href = "/login";
                return Promise.reject(new Error('Token expired'));
            }
        }

        // Если данные являются FormData, не добавляем appInfo
        if (!(config.data instanceof FormData)) {
            const appInfo = {
                deviceManufacturer: 'Your Device Manufacturer',
                deviceModel: 'Your Device Model',
                deviceUniqueId: 'Your Device Unique ID',
                appName: 'Your App Name',
                appVersion: 'Your App Version',
                clientLanguage: 'Your Client Language',
            };

            config.data = {
                ...config.data,
                appInfo,
            };
        }

        return config;
    },
    (error: AxiosError): Promise<AxiosError> => {
        return Promise.reject(error);
    }
);


export const baseApi = {
    bearerAuth,
    parseJwt,
    handleLogError,
};

export default instance;
