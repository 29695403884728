import React from 'react';
import AnimatedCheckmark from '../../atom/AnimatedCheckmark';

const SuccessStep: React.FC = () => (
    <div className="flex flex-col items-center justify-center">
        <p className="text-lg text-slate-800">Авторизация успешна!</p>
        <AnimatedCheckmark/>
    </div>
);

export default SuccessStep;
