export const formatMessageBody = (text: string): React.ReactNode[] => {
    const urlRegex = /((https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\/[^\s]*)?)/g;
    const wordBreakThreshold = 30;

    // Заменяем табуляции на четыре пробела
    text = text.replace(/\t/g, '    ');

    // Заменяем HTML-символы на сущности
    text = text
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;');

    const parts = [];
    let lastIndex = 0;

    text.replace(urlRegex, (match, url, protocol, domain, path, offset) => {
        if (offset > lastIndex) {
            parts.push(text.slice(lastIndex, offset));
        }

        const fullUrl = url.startsWith('https') ? url : `https://${url}`;
        parts.push(
            <a key={offset} href={fullUrl} target="_blank" rel="noopener noreferrer"
               className="text-blue-600 break-all underline">
                {url}
            </a>
        );

        lastIndex = offset + url.length;
        return url;
    });

    if (lastIndex < text.length) {
        parts.push(text.slice(lastIndex));
    }

    return parts.flatMap((part, idx) => {
        if (typeof part === 'string') {
            // Разбиваем текст на строки по символу \n
            const lines = part.split('\n');

            return lines.flatMap((line, lineIdx) => {
                const words = line.split(/(\s+)/);

                const wordElements = words.map((word, wordIdx) => (
                    word.length > wordBreakThreshold
                        ? <span key={`${idx}-${lineIdx}-${wordIdx}`} className="break-all">{word}</span>
                        : word
                ));

                // Добавляем <br /> после каждой строки, кроме последней
                return lineIdx < lines.length - 1 ? [...wordElements,
                    <br key={`${idx}-${lineIdx}-br`}/>] : wordElements;
            });
        }
        return part;
    });
};
