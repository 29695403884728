import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../../redux";
import {clientWsContent} from "../../../api/client-ws";
import Attachment from "../../atom/Attachment";

const Attachments: React.FC = () => {
    const attachments = useSelector((state: RootState) => state.inbox.attachments);
    const [fileUrls, setFileUrls] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        const fetchFilePreviews = async () => {
            try {
                const urls = await Promise.all(
                    attachments.map(async (file) => {
                        const response = await clientWsContent.getFilePreview(file.fileId);
                        const blob = new Blob([response.data], {type: file.contentType});
                        return {fileId: file.fileId, url: URL.createObjectURL(blob)};
                    })
                );

                const urlMap = urls.reduce((acc, {fileId, url}) => ({...acc, [fileId]: url}), {});
                setFileUrls(urlMap);
            } catch (error) {
                console.error('Error rendering attachments:', error);
            }
        };

        if (attachments.length > 0) {
            fetchFilePreviews();
        }

        return () => {
            Object.values(fileUrls).forEach((url) => URL.revokeObjectURL(url));
        };
        // eslint-disable-next-line
    }, [attachments]);

    return useMemo(() => (
        attachments.length > 0 ? (
            <div className="attachments-container flex flex-wrap gap-2 shadow p-2">
                {attachments.map((file) => (
                    <div key={file.fileId} className="attachment-item">
                        <Attachment file={file} url={fileUrls[file.fileId]}/>
                    </div>
                ))}
            </div>
        ) : null
    ), [attachments, fileUrls]);
};

export default Attachments;
