import React, {ReactNode} from 'react';

interface ButtonProps {
    title?: string;
    icon?: ReactNode;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    buttonColor?: ButtonColor;
    buttonSize?: ButtonSize;
    customClass?: string;
    disabled?: boolean;
    type?: "button" | "submit" | "reset" | undefined;
}

export const enum ButtonColor {
    GREEN,
    TRANSPARENT,
    SLATE
}

export const enum ButtonSize {
    SMALL,
    NORMAL,
    MEDIUM,
    FULL,
}

const Button: React.FC<ButtonProps> = ({
                                           title, onClick,
                                           icon, buttonColor,
                                           buttonSize, customClass,
                                           disabled = false, type
                                       }) => {
    let colorClass: string;
    let buttonSizeClass: string;
    switch (buttonColor) {
        case ButtonColor.GREEN:
            colorClass = "bg-button-green-bg text-button-green-text hover:bg-button-green-hover-bg";
            break;
        case ButtonColor.TRANSPARENT:
            colorClass = "bg-transparent text-slate-500 hover:text-slate-700 hover:bg-slate-100";
            break;
        case ButtonColor.SLATE:
            colorClass = "bg-slate-100 text-slate-500 hover:text-slate-700 hover:bg-slate-100";
            break;
        default:
            colorClass = "";
            break;
    }

    switch (buttonSize) {
        case ButtonSize.SMALL:
            buttonSizeClass = "w-6 h-8";
            break;
        case ButtonSize.NORMAL:
            buttonSizeClass = "w-8 h-8";
            break;
        case ButtonSize.MEDIUM:
            buttonSizeClass = "w-12 h-8";
            break;
        case ButtonSize.FULL:
            buttonSizeClass = "w-full px-3 h-8";
            break;
        default:
            buttonSizeClass = "";
            break;
    }

    return (
        <div className={`
                            transition-transform duration-300 transform 
                            active:translate-y-0.5
                        `}>
            <button
                onClick={onClick}
                disabled={disabled}
                type={type}
                className={`
                    ${buttonSizeClass}
                    ${customClass}
                    m-auto
                    rounded-md
                    text-lg 
                    flex items-center justify-center
                    transition-colors duration-300
                    ${colorClass}
                    focus:outline-none
                `}
            >
                {title}
                {icon}
            </button>
        </div>
    );
};

export default Button;