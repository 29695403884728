import React from 'react';

const ActivationPage: React.FC = () => {
    return (
        <>
        </>
    );
};

export default ActivationPage;

// const ActivationPage: React.FC = () => {
//     const {getUser} = useAuth();
//     const user = getUser() as AuthSignupRequest;
//     const navigate = useNavigate();
//     const [error, setError] = useState<string>('');
//     const [activationCode, setActivationCode] = useState<string>('');
//     const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
//     const [waitingTime, setWaitingTime] = useState<number>(0);
//
//     const inputRef = useRef<HTMLInputElement>(null);
//
//     const handleActivationCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
//         setError('');
//         const value = event.target.value;
//
//         if (!isNaN(Number(value)) && value.length <= 6) {
//             setActivationCode(value);
//
//             if (value.length === 6) {
//                 authWs.verifyActivationCode(user, value)
//                     .then(() => {
//                         // Если активация прошла успешно, перенаправляем пользователя на главную страницу
//                         authWs.getUserMe(user).then(() => {
//                             navigate('/'); // Используем navigate для перенаправления
//                         });
//                     })
//                     .catch((error) => {
//                         if (error.response && error.response.data && error.response.data.error) {
//                             setError(error.response.data.error);
//                         } else {
//                             console.error('Ошибка при отправке запроса:', error);
//                         }
//                     });
//             }
//         }
//     };
//
//     const clearInput = () => {
//         setActivationCode('');
//         setError('');
//     };
//
//
//     const handleSendActivationCodeSubmit = () => {
//         clearInput();
//
//         authWs.sendActivationCode(user)
//             .then((response) => {
//                 const waitingTimeSeconds = response.data['waiting time'];
//
//                 if (!isNaN(waitingTimeSeconds)) {
//                     setWaitingTime(waitingTimeSeconds);
//                     setIsButtonDisabled(true);
//
//                     const timer = setInterval(() => {
//                         setWaitingTime((prevTime) => prevTime - 1);
//                     }, 1000);
//
//                     setTimeout(() => {
//                         clearInterval(timer);
//                         setIsButtonDisabled(false);
//                     }, waitingTimeSeconds * 1000); // Умножаем на 1000 для миллисекунд
//                 } else {
//                     console.error('Invalid date format:', response.data['waiting time']);
//                 }
//             })
//             .catch((error) => {
//                 if (error.response && error.response.data && error.response.data.error) {
//                     console.error(error.response.data.error);
//                 } else {
//                     console.error('Ошибка при отправке запроса:', error);
//                 }
//             });
//     };
//
//     return (
//         <div className="max-w-md mx-auto p-6 text-center text-slate-600">
//             <h1 className="text-2xl font-bold mb-4">Добро пожаловать в наше приложение!</h1>
//             <p className="mb-4">Пожалуйста, введите код активации для активации вашей учетной записи:</p>
//             <div className="flex justify-center items-center mb-4">
//                 <input
//                     ref={inputRef}
//                     type="text"
//                     maxLength={6}
//                     value={activationCode}
//                     onChange={handleActivationCodeChange}
//                     className="w-full text-2xl py-2 px-4 border border-slate-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
//                 />
//                 <button
//                     onClick={handleSendActivationCodeSubmit}
//                     className={`py-2 px-4 border border-slate-300 bg-transparent rounded-r-md ${isButtonDisabled ? 'text-blue-500' : 'text-slate-800'} hover:text-blue-700`}
//                     disabled={isButtonDisabled}
//                 >
//                     {isButtonDisabled ? `Отправить повторно (${waitingTime} сек)` : 'Получить код'}
//                 </button>
//             </div>
//             {error && <div className="text-red-500 text-left text-sm mt-2">{error}</div>}
//         </div>
//     );
// };
//
// export default ActivationPage;
