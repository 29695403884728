import React, {ChangeEvent, useEffect} from 'react';
import StatusBadge from '../../atom/StatusBadge';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../redux";
import {ChannelType, Connection, ConnectionStatus, ModalType} from "../../types/types";
import {clientWsAvitoChannel, clientWsTelegramChannel, clientWsWhatsappChannel} from "../../../api/client-ws";
import {setTypeOfModalToBeShown} from "../../../redux/slices/common-slice";
import {setConnection, updateConnection} from "../../../redux/slices/connections-slice";
import ModalWrapper from "../../atom/ModalWrapper";
import InputField, {InputType} from "../../atom/InputField";
import ConnectionStatusSection from "./ConnectionStatusSection";

const ConnectionsEditModal: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const connection = useSelector((state: RootState) => state.connections.connection);
    const typeOfModalToBeShown = useSelector((state: RootState) => state.common.typeOfModalToBeShown);

    useEffect(() => {
        const fetchConnectionData = async () => {
            if (connection?.channelId) {
                try {
                    let updatedConnection = null;
                    switch (connection.channelType) {
                        case ChannelType.WHATSAPP:
                            updatedConnection = await clientWsWhatsappChannel.get(connection.channelId);
                            break;
                        case ChannelType.TELEGRAM:
                            updatedConnection = await clientWsTelegramChannel.get(connection.channelId);
                            break;
                        case ChannelType.AVITO:
                            updatedConnection = await clientWsAvitoChannel.get(connection.channelId);
                            break;
                    }

                    if (updatedConnection && updatedConnection.data) {
                        // Если channelType не пришел с бэкенда, подставляем старое значение
                        updatedConnection.data.channelType = updatedConnection.data.channelType || connection.channelType;
                        // Обновляем данные в Redux
                        dispatch(setConnection(updatedConnection.data));
                        dispatch(updateConnection(updatedConnection.data));
                    }
                } catch (error) {
                    console.error(`Ошибка при получении данных для канала ${connection?.channelType}:`, error);
                }
            }
        };

        if (typeOfModalToBeShown === ModalType.EditConnection && connection?.channelId) {
            fetchConnectionData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeOfModalToBeShown, connection?.channelId]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        const updatedConnection = {...connection, [name]: value};
        dispatch(setConnection(updatedConnection));
    };

    const handleSubmit = async () => {
        const channelDto: Connection = {
            title: connection?.title || '',
            channelId: connection?.channelId || '',
            channelType: connection?.channelType || '',
            status: connection?.status || ConnectionStatus.SESSION_ERROR,
        };

        if (connection) {
            try {
                switch (connection.channelType) {
                    case ChannelType.WHATSAPP:
                        await clientWsWhatsappChannel.update(connection.channelId, channelDto);
                        break;
                    case ChannelType.TELEGRAM:
                        await clientWsTelegramChannel.update(connection.channelId, channelDto);
                        break;
                    case ChannelType.AVITO:
                        await clientWsAvitoChannel.update(connection.channelId, channelDto);
                        break;
                }
                dispatch(updateConnection(channelDto));
                onRequestClose();
            } catch (error) {
                console.error(`Ошибка при обновлении канала ${connection.channelType}:`, error);
            }
        }
    };

    const onRequestClose = () => {
        dispatch(setTypeOfModalToBeShown(null));
    };

    return connection ? (
        <ModalWrapper
            isOpen={typeOfModalToBeShown === ModalType.EditConnection}
            onRequestClose={onRequestClose}
            contentLabel="Редактирование"
            badge={connection.channelType}
            okButtonText="Сохранить"
            cancelButtonText="Отмена"
            onOkButtonClick={handleSubmit}
            badgeRotate={true}
        >
            <div className="flex flex-col space-y-4">
                <InputField type={InputType.TEXT} name="title" value={connection.title as string}
                            onChange={handleChange}
                            placeholder={"Название подключения"}/>
                <StatusBadge className={`w-full`} status={connection.status as ConnectionStatus}/>

                <ConnectionStatusSection/>

            </div>
        </ModalWrapper>
    ) : null;
};

export default ConnectionsEditModal;
