import React from 'react';

interface CheckboxFieldProps {
    name: string;
    checked: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    label: React.ReactNode;
    className?: string;
    error?: string;  // Добавлено для отображения ошибки
}

const CheckboxField: React.FC<CheckboxFieldProps> = ({name, checked, onChange, label, className = '', error}) => {
    return (
        <div className={`${className} relative`}>
            <div className="flex items-center justify-between space-x-3 shadow-md2 py-2 px-3 rounded-lg">
                <label className={`text-slate-600 text-sm text-left`}>
                    {label}
                </label>
                <div className="relative flex">
                    <input
                        type="checkbox"
                        name={name}
                        checked={checked}
                        onChange={onChange}
                        className={`
                            appearance-none
                            w-9 h-9
                            rounded-md
                            cursor-pointer
                            ${checked ? 'shadow-none' : 'shadow hover:shadow-md2'}
                            checked:bg-white
                            checked:text-green-600
                            checked:border-transparent
                            focus:outline-none
                            transition duration-200
                            ${error ? 'border border-red-400' : ''}
                        `}
                    />
                    <svg
                        className={`
                            absolute
                            top-1/2 left-1/2
                            w-7 h-7
                            ${checked ? 'text-green-600' : 'text-white'}
                            transform -translate-x-1/2 -translate-y-1/2
                            pointer-events-none
                            transition-colors duration-300
                        `}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={3}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M5 13l4 4L19 7"
                        />
                    </svg>
                </div>
            </div>
            {error && (
                <p className="absolute text-red-500 text-xs mt-1 leading-tight ml-2 max-w-full w-full break-words">
                    {error}
                </p>
            )}

        </div>
    );
};

export default CheckboxField;
