import React, {useContext, useEffect, useState} from 'react';
import {Navigate, NavLink} from 'react-router-dom';
import AuthContext from "../../context/AuthContext";
import {authWsSignup} from "../../api/auth-ws";
import {handleLogError} from "../../api/base-api";
import {Helmet} from "react-helmet";
import FormWrapper from "../atom/FormWrapper";
import InputField, {InputType} from "../atom/InputField";
import CheckboxField from "../atom/ChekboxField";
import Button, {ButtonColor, ButtonSize} from "../atom/Button";
import ErrorMessage from "../atom/ErrorMessage";

interface SignupFormProps {
    inviteCode?: string;
    isInviteValid?: boolean;
    onValidateInvite?: (inviteCode: string) => void;
}

const SignupForm: React.FC<SignupFormProps> = ({inviteCode, isInviteValid, onValidateInvite}) => {
    const auth = useContext(AuthContext);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        name: '',
        terms: false,
        gdpr: false
    });

    const [isLoggedIn, setIsLoggedIn] = useState(auth?.userIsAuthenticated() ?? false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (inviteCode && onValidateInvite) {
            onValidateInvite(inviteCode);
        }
    }, [inviteCode, onValidateInvite]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value, checked, type} = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
        setErrors(prev => ({...prev, [name]: ''})); // Убираем ошибку при изменении
    };

    const validateForm = () => {
        const newErrors: { [key: string]: string } = {};
        if (!formData.email) newErrors.email = 'Email обязателен';
        if (!formData.password) newErrors.password = 'Пароль обязателен';
        if (!formData.name) newErrors.name = 'Имя обязательно';
        if (!formData.terms) newErrors.terms = 'Вы должны согласиться с условиями';
        if (!formData.gdpr) newErrors.gdpr = 'Вы должны согласиться с условиями';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!validateForm()) return;

        try {
            const response = await authWsSignup.signUp({...formData}, inviteCode);

            if (auth) {
                auth.userLogin(response.data.token);
            }

            setIsLoggedIn(true);
        } catch (error: any) {
            const serverErrors: { [key: string]: string } = {};
            if (error.response?.data?.code === 'ERR_VALIDATION_ERROR') {
                if (error.response.data.message === 'Email already exists') {
                    serverErrors.email = 'Этот email уже используется';
                }
                if (error.response.data.errorDetails.includes('PASSWORD_ILLEGAL_USERNAME')) {
                    serverErrors.password = 'Пароль не должен содержать ваш email';
                }
            } else {
                serverErrors.form = error.response?.data?.message || 'Ошибка при регистрации';
            }

            setErrors(prev => ({...prev, ...serverErrors}));
            handleLogError(error); // Логирование ошибки
        }
    };


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    if (isLoggedIn) {
        return <Navigate to="/"/>;
    }

    return (
        <div className="flex justify-center items-center h-screen ">
            <Helmet>
                <title>Регистрация | ChatHolder</title>
            </Helmet>
            <div>
                <FormWrapper onSubmit={handleSubmit}>
                    {inviteCode !== undefined && (
                        <InputField
                            type={InputType.TEXT}
                            name="inviteCode"
                            placeholder="Invite-код"
                            value={inviteCode}
                            onChange={handleInputChange}
                            disabled={isInviteValid}
                        />
                    )}
                    {(inviteCode === undefined || isInviteValid) && (
                        <>
                            <InputField
                                type={InputType.EMAIL}
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleInputChange}
                                error={errors.email}
                            />
                            <InputField
                                type={showPassword ? InputType.TEXT : InputType.PASSWORD}
                                name="password"
                                placeholder="Пароль"
                                value={formData.password}
                                onChange={handleInputChange}
                                showPassword={showPassword}
                                togglePasswordVisibility={togglePasswordVisibility}
                                error={errors.password}
                            />
                            <InputField
                                type={InputType.TEXT}
                                name="name"
                                placeholder="Имя"
                                value={formData.name}
                                onChange={handleInputChange}
                                error={errors.name}
                            />
                            <CheckboxField
                                name="gdpr"
                                checked={formData.gdpr}
                                onChange={handleInputChange}
                                label={
                                    <span>Я согласен с <a
                                        href="https://docs.google.com/document/d/1FtplZVnPEKPTU97o_Yn_Ik3rDyN2s_pb"
                                        target="_blank" rel="noopener noreferrer"
                                        className="text-green-600 hover:underline">
                                    политикой конфиденциальности</a>
                                </span>
                                }
                                error={errors.gdpr}
                            />
                            <CheckboxField
                                name="terms"
                                checked={formData.terms}
                                onChange={handleInputChange}
                                label={
                                    <span>Я согласен с <a
                                        href="https://docs.google.com/document/d/1FrYM0PkPglEyG0zZxTeI14kaY6jxoXxa"
                                        target="_blank" rel="noopener noreferrer"
                                        className="text-green-600 hover:underline">
                                    пользовательским соглашением</a>
                                </span>
                                }
                                className={errors.terms ? 'border-red-500' : ''}
                                error={errors.terms}
                            />
                            <div className={`relative`}>
                                <Button
                                    title={"Зарегистрироваться"}
                                    buttonSize={ButtonSize.FULL}
                                    type="submit"
                                    buttonColor={ButtonColor.GREEN}
                                    customClass={"py-6"}
                                />
                                {errors.form && <ErrorMessage message={errors.form}/>}
                            </div>
                        </>
                    )}
                </FormWrapper>
                <div className="mt-5 text-center">
                <span className="text-slate-600">Уже есть аккаунт?&nbsp;
                    <NavLink to='/login' className="text-green-600 hover:underline">Авторизоваться</NavLink>
                </span>
                </div>
            </div>
        </div>
    );
};

export default SignupForm;
