import React, {useCallback, useEffect, useState} from 'react';
import Button, {ButtonColor, ButtonSize} from '../../atom/Button';
import QrCodeStep from "../addConnection/QrCodeStep";
import LoginByUrlStep from "../addConnection/LoginByUrlStep";
import WhatsAppConnectionDescription from "../addConnection/createWhatsAppConnection/WhatsAppConnectionDescription";
import TelegramConnectionDescription from "../addConnection/createTelegramConnection/TelegramConnectionDescription";
import AvitoConnectionDescription from "../addConnection/createAvitoConnection/AvitoConnectionDescription";
import {ChannelType, ConnectionStatus, ModalType} from '../../types/types';
import {clientWsAvitoChannel, clientWsTelegramChannel, clientWsWhatsappChannel} from '../../../api/client-ws';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../redux";
import {setConnection, updateConnection} from "../../../redux/slices/connections-slice";

const ConnectionStatusSection: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const typeOfModalToBeShown = useSelector((state: RootState) => state.common.typeOfModalToBeShown);

    const [qrCode, setQrCode] = useState<string | null>(null);
    const [authUrl, setAuthUrl] = useState<string | null>(null);

    const connection = useSelector((state: RootState) => state.connections.connection);

    const recreateSessionHandler = async () => {
        if (!connection?.channelId) return;
        try {
            switch (connection?.channelType) {
                case ChannelType.WHATSAPP:
                    await clientWsWhatsappChannel.init(connection.channelId);
                    break;
                case ChannelType.TELEGRAM:
                    await clientWsTelegramChannel.init(connection.channelId);
                    break;
                case ChannelType.AVITO:
                    await clientWsAvitoChannel.init(connection.channelId);
                    break;
            }
        } catch (error) {
            console.error(`Ошибка при повторной авторизации для канала ${connection?.channelType}:`, error);
        }
    };

    const showAuthHandler = useCallback(async () => {
        if (!connection?.channelId || !['SESSION_ERROR', 'INIT'].includes(connection.status)) return;

        try {
            switch (connection?.channelType) {
                case ChannelType.WHATSAPP:
                    const whatsappResponse = await clientWsWhatsappChannel.getAuthQrCode(connection.channelId);
                    setQrCode(whatsappResponse.data.qrCode);
                    break;
                case ChannelType.TELEGRAM:
                    const telegramResponse = await clientWsTelegramChannel.getAuthQrCode(connection.channelId);
                    setQrCode(telegramResponse.data.qrCode);
                    break;
                case ChannelType.AVITO:
                    const avitoResponse = await clientWsAvitoChannel.getAuthUrl(connection.channelId);
                    setAuthUrl(avitoResponse.data.url);
                    break;
            }
        } catch (error) {
            console.error(`Ошибка при получении данных для канала ${connection?.channelType}:`, error);
        }
    }, [connection?.channelId, connection?.status, connection?.channelType]);

    useEffect(() => {
        let qrCodeInterval: ReturnType<typeof setInterval> | null = null;

        if (connection?.channelId && ['SESSION_ERROR', 'INIT'].includes(connection.status)) {
            qrCodeInterval = setInterval(showAuthHandler, 5000);
        }

        return () => {
            if (qrCodeInterval) clearInterval(qrCodeInterval); // Очищаем пулинг при закрытии
        };
    }, [connection?.channelId, connection?.status, showAuthHandler]);

    const connectionDescriptionMap: Partial<Record<ChannelType, React.ReactElement>> = {
        [ChannelType.WHATSAPP]: <WhatsAppConnectionDescription showAsList={true}/>,
        [ChannelType.TELEGRAM]: <TelegramConnectionDescription showAsList={true}/>,
        [ChannelType.AVITO]: <AvitoConnectionDescription showAsList={true}/>,
    };

    const renderStepMap: Partial<Record<ChannelType, React.ReactElement>> = {
        [ChannelType.WHATSAPP]: <QrCodeStep qrCode={qrCode}/>,
        [ChannelType.TELEGRAM]: <QrCodeStep qrCode={qrCode}/>,
        [ChannelType.AVITO]: <LoginByUrlStep loginUrl={authUrl}/>,
    };

    useEffect(() => {
        let pollingInterval: ReturnType<typeof setInterval> | null = null;

        const startPolling = () => {
            pollingInterval = setInterval(async () => {
                if (connection?.channelId) {
                    try {
                        let updatedConnection = null;
                        switch (connection?.channelType) {
                            case ChannelType.WHATSAPP:
                                updatedConnection = await clientWsWhatsappChannel.get(connection.channelId);
                                break;
                            case ChannelType.TELEGRAM:
                                updatedConnection = await clientWsTelegramChannel.get(connection.channelId);
                                break;
                            case ChannelType.AVITO:
                                updatedConnection = await clientWsAvitoChannel.get(connection.channelId);
                                break;
                        }

                        if (updatedConnection && updatedConnection.data) {
                            const newStatus = updatedConnection.data.status;
                            if (newStatus !== connection?.status) {
                                const tmpConnection = {...connection, status: newStatus}
                                dispatch(setConnection(tmpConnection));
                                dispatch(updateConnection(tmpConnection));
                            }
                        }
                    } catch (error) {
                        console.error('Ошибка при проверке статуса соединения:', error);
                    }
                }
            }, 3000); // Интервал 3 секунды
        };

        if (connection?.channelId && typeOfModalToBeShown === ModalType.EditConnection) {
            startPolling();
        }

        // Останавливаем пулинг при закрытии модального окна
        return () => {
            if (pollingInterval) {
                clearInterval(pollingInterval);
                pollingInterval = null;
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connection, typeOfModalToBeShown]);

    return (
        <div>
            {[ConnectionStatus.SESSION_TERMINATED, ConnectionStatus.SESSION_ERROR].includes(connection?.status as ConnectionStatus) && (
                <Button title={`Возобновить соединение`} buttonColor={ButtonColor.GREEN}
                        onClick={recreateSessionHandler}
                        buttonSize={ButtonSize.FULL} customClass={`shadow`}/>
            )}

            {[ConnectionStatus.INIT].includes(connection?.status as ConnectionStatus) && (
                <>
                    <div className={`mb-4`}>
                        {connectionDescriptionMap[connection?.channelType as ChannelType] || null}
                    </div>
                    {renderStepMap[connection?.channelType as ChannelType] || null}
                </>
            )}
        </div>
    );
};

export default ConnectionStatusSection;
