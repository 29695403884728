import React, {useEffect, useState} from 'react';
import {clientWsMultiUsers, clientWsUserInvitation} from "../../../api/client-ws";
import {InvitationLinkResponse, TeammatesResponse, TeammateWithInvite} from "../../types/types";
import {format} from 'date-fns';
import {ru} from 'date-fns/locale';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {FaTrash} from "react-icons/fa6";
import Button, {ButtonColor} from "../../atom/Button";

const TeammatesTable: React.FC = () => {
    const [teammates, setTeammates] = useState<TeammateWithInvite[]>([]);

    useEffect(() => {
        const fetchConnections = async () => {
            try {
                const [usersResponse, linksResponse] = await Promise.all([
                    clientWsMultiUsers.get(),
                    clientWsUserInvitation.get()
                ]);

                const users: TeammatesResponse[] = usersResponse.data;
                const links: InvitationLinkResponse[] = linksResponse.data;

                const combinedData: TeammateWithInvite[] = [
                    ...users.map(user => ({teammate: user, invitationLink: undefined})),
                    ...links.map(link => ({teammate: undefined, invitationLink: link}))
                ];

                setTeammates(combinedData);
            } catch (error) {
                console.error('Ошибка при получении данных:', error);
            }
        };

        fetchConnections();
    }, []);

    const handleCopyText = async (text: string, successMessage: string) => {
        try {
            await navigator.clipboard.writeText(text);
            toast.success(successMessage);
        } catch (error) {
            console.error('Ошибка копирования:', error);
        }
    };

    const handleDeleteInviteCode = async (inviteCode: string) => {
        try {
            await clientWsUserInvitation.delete(inviteCode);
            toast.success('Инвайт код удален!');
        } catch (error) {
            console.error('Ошибка удаления инвайт кода:', error);
        }
    };

    const formatDate = (dateString: string | undefined): string => {
        if (!dateString) return '-';
        try {
            return format(new Date(dateString), 'dd.MM.yyyy', {locale: ru});
        } catch (error) {
            console.error('Ошибка форматирования даты:', error);
            return '-';
        }
    };

    return (
        <>
            <table className="min-w-full bg-white border border-slate-200 shadow-sm">
                <thead className="h-14 bg-slate-100 text-slate-600 border-b">
                <tr>
                    <th className="py-2 px-4 w-16"></th>
                    <th className="py-2 px-4 w-64">Имя пользователя</th>
                    <th className="py-2 px-4 w-24">Email</th>
                    <th className="py-2 px-4 w-40">Дата создания</th>
                    <th className="py-2 px-4 w-72">Инвайт код</th>
                    <th className="py-2 px-4 w-72">Ссылка для регистрации</th>
                    <th className="py-2 px-4 w-8">Действия</th>
                </tr>
                </thead>
                <tbody>
                {teammates.map((item, index) => (
                    <tr key={index} className="border-b hover:bg-slate-50 transition text-center">
                        <td className="px-4 py-2"></td>
                        <td className="px-4 py-2">{item.teammate?.fullName || '-'}</td>
                        <td className="px-4 py-2">{item.teammate?.email || '-'}</td>
                        <td className="px-4 py-2">
                            {formatDate(item.invitationLink?.created || item.teammate?.registrationDate)}
                        </td>
                        <td
                            className="px-4 py-2 cursor-pointer text-blue-600 hover:underline"
                            onClick={() => handleCopyText(item.invitationLink?.inviteCode || '', 'Инвайт код скопирован!')}
                        >
                            {item.invitationLink?.inviteCode || ''}
                        </td>
                        <td
                            className="px-4 py-2 cursor-pointer text-blue-600 hover:underline"
                            onClick={() => handleCopyText(`${window.location.origin}/signup-teammate?${item.invitationLink?.inviteCode || ''}`, 'Ссылка для регистрации скопирована!')}
                        >
                            {item.invitationLink?.inviteCode ? 'Ссылка для регистрации' : ''}
                        </td>
                        <td className="px-4 py-2 flex items-center justify-center">
                            {item.invitationLink?.inviteCode && (
                                <Button
                                    onClick={() => handleDeleteInviteCode(item.invitationLink?.inviteCode || '')}
                                    icon={<FaTrash/>}
                                    buttonColor={ButtonColor.TRANSPARENT}
                                />
                            )}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <ToastContainer position="top-right" autoClose={2000}/>
        </>
    );
};

export default TeammatesTable;
