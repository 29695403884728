import useLocalStorageService from "../utils/useLocalStorageService";
import {clientWsUserProfile} from "../api/client-ws";
import {RoleType, StatusType} from "../components/types/types";

export const useProfileHelper = () => {
    const storageService = useLocalStorageService(); // Вызов хука единожды

    const loadProfileData = async () => {
        const responseProfile = await clientWsUserProfile.load();
        storageService.save("role", responseProfile.data.role);
        storageService.save("status", responseProfile.data.status);
    };

    const updateRole = (newRole: string) => {
        storageService.update("role", newRole);
    };

    const updateStatus = (newStatus: string) => {
        storageService.update("status", newStatus);
    };

    const getRole = (): RoleType => {
        return storageService.get("role") as RoleType;
    };

    const getStatus = (): StatusType => {
        return storageService.get("status") as StatusType;
    };

    const removeProfileData = () => {
        storageService.remove("role");
        storageService.remove("status");
    };

    return {
        loadProfileData,
        updateRole,
        updateStatus,
        getRole,
        getStatus,
        removeProfileData,
    };
};
