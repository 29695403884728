import React from 'react';

interface ContentWrapperProps {
    children: React.ReactNode;
    fullHeight?: boolean;
    customClass?: string;
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({
                                                           children, fullHeight = true,
                                                           customClass
                                                       }) => {
    return (
        <div
            className={`flex flex-col ${fullHeight ? 'h-full' : ''} 
            box-border bg-white shadow-md2 rounded-xl
            ${customClass ? customClass : ''}`}>
            {children}
        </div>
    );
};

export default ContentWrapper;
