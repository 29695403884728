import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../redux";
import {ModalType} from "../../types/types";
import ModalWrapper from "../../atom/ModalWrapper";
import {setTypeOfModalToBeShown} from "../../../redux/slices/common-slice";
import {clientWsUserProfile} from "../../../api/client-ws";
import {setUseEnterToSend} from "../../../redux/slices/inbox-slice";

const InboxSettingsModal: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const typeOfModalToBeShown = useSelector((state: RootState) => state.common.typeOfModalToBeShown);
    const useEnterToSend = useSelector((state: RootState) => state.inbox.useEnterToSend) === 'true';

    const onRequestClose = () => {
        dispatch(setTypeOfModalToBeShown(null));
    };

    async function handleSubmit() {
        const settingDto = {
            key: "useEnterToSend",
            value: String(useEnterToSend), // Преобразование в строку для отправки на сервер
        };
        try {
            await clientWsUserProfile.updateSettingsProperty(settingDto);
            dispatch(setTypeOfModalToBeShown(null));
        } catch (error) {
            console.error('Error updating user profile:', error);
        }
    }

    const toggleSwitch = () => {
        dispatch(setUseEnterToSend(String(!useEnterToSend))); // Переключаем значение и сохраняем его как строку
    };

    return (
        <ModalWrapper
            isOpen={typeOfModalToBeShown === ModalType.InboxSettings}
            onRequestClose={onRequestClose}
            contentLabel="Настройки"
            okButtonText="Сохранить"
            cancelButtonText="Отмена"
            onOkButtonClick={handleSubmit}
            badgeRotate={true}
        >
            <div className="flex flex-col">
                <label
                    className={`flex pb-4 items-center space-x-3 ${useEnterToSend ? 'text-slate-800' : 'text-slate-400'}`}>
                    <span>Включить отправку сообщений по Enter</span>
                    <div
                        className="relative inline-block w-10 align-middle select-none transition duration-200 ease-in">
                        <input
                            type="checkbox"
                            name="toggle"
                            id="toggle"
                            className="toggle-checkbox hidden"
                            checked={useEnterToSend}
                            onChange={toggleSwitch}
                        />
                        <label
                            htmlFor="toggle"
                            className={`toggle-label block overflow-hidden h-6 rounded-full bg-slate-300 cursor-pointer ${useEnterToSend ? 'bg-green-500' : 'bg-slate-300'}`}
                        >
                            <span
                                className={`toggle-dot absolute left-1 my-1 w-4 h-4 bg-white rounded-full shadow transition-transform duration-300 ease-in-out transform ${useEnterToSend ? 'translate-x-4' : ''}`}
                            ></span>
                        </label>
                    </div>
                </label>
            </div>
        </ModalWrapper>
    );
};

export default InboxSettingsModal;
