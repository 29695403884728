import React, {ReactNode} from 'react';
import Modal from 'react-modal';
import SocialNetworkBadge from "./SocialNetworkBadge";
import {FaTimes} from "react-icons/fa";
import Button, {ButtonColor, ButtonSize} from "./Button";

interface ModalWrapperProps {
    isOpen: boolean;
    onRequestClose: () => void;
    contentLabel: string;
    children: ReactNode;
    badge?: string;
    badgeRotate?: boolean;
    okButtonText?: string;
    cancelButtonText?: string;
    onOkButtonClick?: () => void;
    okButtonDisabled?: boolean;
}

Modal.setAppElement('#root');

const ModalWrapper: React.FC<ModalWrapperProps> = ({
                                                       isOpen,
                                                       onRequestClose,
                                                       contentLabel,
                                                       children,
                                                       badge,
                                                       badgeRotate,
                                                       okButtonText,
                                                       cancelButtonText,
                                                       onOkButtonClick,
                                                       okButtonDisabled,
                                                   }) => (
    <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel={contentLabel}
        className="bg-white rounded-lg shadow-lg max-w-lg mx-auto p-6 max-h-[90vh] overflow-y-auto" // Добавлено max-h и overflow-y
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
        <header className="flex justify-between items-center mb-5">
            <h2 className="m-0 text-xl font-bold text-slate-800">{contentLabel}</h2>
            {badge &&
                <div className={`relative transform ${badgeRotate ? '-rotate-3' : ''}`}>
                    <SocialNetworkBadge network={badge} short={false}
                                        inverted={false} background={true}/>
                </div>
            }
            <Button onClick={onRequestClose} buttonColor={ButtonColor.TRANSPARENT} buttonSize={ButtonSize.NORMAL}
                    icon={<FaTimes/>}/>
        </header>
        {children}
        <footer className="flex justify-end space-x-3 pt-5">
            <button
                className="w-1/4 bg-slate-300 text-slate-800 p-2 rounded-lg hover:bg-slate-400 transition  duration-200"
                onClick={onRequestClose}
            >
                {cancelButtonText || 'Отмена'}
            </button>
            <button
                className={`w-1/4 bg-blue-500 text-white p-2 rounded-lg transition duration-200 ${okButtonDisabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
                onClick={onOkButtonClick}
                disabled={okButtonDisabled}
            >
                {okButtonText || 'OK'}
            </button>
        </footer>
    </Modal>
);

export default ModalWrapper;
