import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AttachmentResponse, ChatInterface, MessageInterface} from "../../components/types/types";

interface InboxState {
    selectedChatId: string | null; // выбранный пользователем чат ID
    selectedChat: ChatInterface | null; // выбранный пользователем чат
    channelId: string | null; // id канала который выбирает пользователь из дропдауна в текстарии инбокса
    messages: MessageInterface[]; // сообщения из выбранного чата
    channelType: string | null; // тип чата (мессенджер)
    filteredChannelId?: string | null; // это connection который используется в фильтре
    messageText?: string | null; // текст сообщения которое мы шлем из textArea
    attachments: AttachmentResponse[]; // аттачи при отправке сообщения
    chats?: ChatInterface[]; // список всех чатов
    showUnread?: boolean; // показать только непрочитанные чаты
    useEnterToSend?: string | null; // опция отправки сообщений по enter бэк возвращает строку т.к. settings хранятся только как строка
}

const initialState: InboxState = {
    selectedChatId: null,
    selectedChat: null,
    channelId: null,
    messages: [],
    channelType: null,
    filteredChannelId: null,
    messageText: null,
    attachments: [],
    chats: [],
    showUnread: false,
    useEnterToSend: null,
};

const inboxSlice = createSlice({
    name: 'inbox',
    initialState,
    reducers: {
        setSelectedChatId(state, action: PayloadAction<string>) {
            state.selectedChatId = action.payload;
        },
        setSelectedChat(state, action: PayloadAction<ChatInterface>) {
            state.selectedChat = action.payload;
        },
        setChannelId(state, action: PayloadAction<string>) {
            state.channelId = action.payload;
        },
        setMessages(state, action: PayloadAction<MessageInterface[]>) {
            state.messages = action.payload.sort(
                (a, b) => new Date(a.messageTimestamp).getTime() - new Date(b.messageTimestamp).getTime()
            );
        },
        pushMessage(state, action: PayloadAction<MessageInterface>) {
            const newMessages = [...state.messages, action.payload];
            newMessages.sort(
                (a, b) => new Date(a.messageTimestamp).getTime() - new Date(b.messageTimestamp).getTime()
            );
            state.messages = newMessages;
        },
        pushMessages(state, action: PayloadAction<MessageInterface[]>) {
            const combinedMessages = [...state.messages, ...action.payload];
            const uniqueMessages = Array.from(
                new Map(combinedMessages.map((msg) => [msg.messageId, msg])).values()
            );
            state.messages = uniqueMessages.sort(
                (a, b) => new Date(a.messageTimestamp).getTime() - new Date(b.messageTimestamp).getTime()
            );
        },
        updateMessage(state, action: PayloadAction<MessageInterface>) {
            const index = state.messages.findIndex((msg) => msg.messageId === action.payload.messageId);
            if (index !== -1) {
                state.messages[index] = action.payload;
            }
        },
        setChannelType(state, action: PayloadAction<string>) {
            state.channelType = action.payload;
        },
        setFilteredChatId(state, action: PayloadAction<string | null>) {
            state.filteredChannelId = action.payload;
        },
        setMessageText(state, action: PayloadAction<string>) {
            state.messageText = action.payload;
        },
        cleanMessagesWithoutId(state) {
            state.messages = state.messages.filter((msg) => !!msg.messageId);
        },
        setAttachments(state, action: PayloadAction<AttachmentResponse[]>) {
            state.attachments = action.payload;
        },
        cleanAttachments(state) {
            state.attachments = [];
        },
        pushAttachment(state, action: PayloadAction<AttachmentResponse>) {
            (state.attachments || []).push(action.payload);
        },
        deleteAttachment(state, action: PayloadAction<String>) {
            state.attachments = state.attachments.filter((attach) => attach.fileId !== action.payload);
        },
        setChats(state, action: PayloadAction<ChatInterface[]>) {
            state.chats = action.payload;
        },
        pushChats(state, action: PayloadAction<ChatInterface[]>) {
            if (!state.chats) {
                state.chats = [];
            }
            const chatMap = new Map(state.chats.map(chat => [chat.chatId, chat]));

            action.payload.forEach(newChat => {
                chatMap.set(newChat.chatId, newChat);
            });

            state.chats = Array.from(chatMap.values());
        },

        cleanChats(state) {
            state.chats = [];
        },
        cleanInboxState(state) {
            state.selectedChatId = null;
            state.channelId = null;
            state.messages = [];
            state.channelType = null;
            state.filteredChannelId = null;
            state.messageText = null;
            state.attachments = [];
            state.chats = [];
            state.showUnread = false;
        },
        setShowUnread(state, action: PayloadAction<boolean>) {
            state.showUnread = action.payload;
        },
        setUseEnterToSend(state, action: PayloadAction<string>) {
            state.useEnterToSend = action.payload.toString();
        }
    },
});

export const {
    setSelectedChatId,
    setSelectedChat,
    setChannelId,
    setMessages,
    pushMessage,
    pushMessages,
    updateMessage,
    setChannelType,
    setFilteredChatId,
    setMessageText,
    cleanMessagesWithoutId,
    setAttachments,
    cleanAttachments,
    pushAttachment,
    deleteAttachment,
    setChats,
    pushChats,
    cleanChats,
    cleanInboxState,
    setShowUnread,
    setUseEnterToSend,
} = inboxSlice.actions;

export default inboxSlice.reducer;
