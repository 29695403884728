import {combineReducers} from '@reduxjs/toolkit';
import inboxReducer from "./slices/inbox-slice";
import connectionsReducer from "./slices/connections-slice";
import commonReducer from "./slices/common-slice";
import sidebarReducer from "./slices/sidebar-slice";

const rootReducer = combineReducers({
    inbox: inboxReducer,
    connections: connectionsReducer,
    common: commonReducer,
    sidebar: sidebarReducer,
});

export default rootReducer;
