import React, {useEffect, useState} from 'react';
import {QRCodeCanvas} from "qrcode.react";

interface QrCodeStepProps {
    qrCode: string | null;
    stopPolling?: boolean; // Новый пропс для остановки пулинга
}

const QrCodeStep: React.FC<QrCodeStepProps> = ({qrCode, stopPolling}) => {
    const [showDelayMessage, setShowDelayMessage] = useState(false);

    useEffect(() => {
        if (stopPolling) return; // Прерываем, если нужно остановить пулинг

        const timer = setTimeout(() => {
            if (!qrCode) {
                setShowDelayMessage(true);
            }
        }, 5000); // 5 секунд

        return () => clearTimeout(timer);
    }, [qrCode, stopPolling]); // Добавляем зависимость от stopPolling

    return (
        <div className="flex flex-col justify-center items-center text-center w-full h-full">
            {qrCode ? (
                <QRCodeCanvas value={qrCode} size={264} className="m-auto"/>
            ) : (
                <>
                    <p className="animate-fadeInOut text-2xl text-slate-800 mb-5 w-full flex justify-center">
                        Загрузка QR-кода...
                    </p>
                    {showDelayMessage && (
                        <p className="text-lg text-slate-600 w-full flex justify-center">
                            Получение QR-кода может занимать до 2 минут. Пожалуйста, оставайтесь на этой странице.
                        </p>
                    )}
                </>
            )}
        </div>
    );
};

export default QrCodeStep;
