import React, {useEffect} from 'react';
import WhatsAppConnectionDescription
    from "../../molecula/addConnection/createWhatsAppConnection/WhatsAppConnectionDescription";
import WhatsAppConnectionSteps from "../../molecula/addConnection/createWhatsAppConnection/WhatsAppConnectionSteps";
import ContentWrapper from "../../atom/ContentWrapper";
import LostMessagesWarning from "../../atom/LostMessagesWarning";
import {useHeader} from "../../../context/HeaderContext";
import SocialNetworkBadge from "../../atom/SocialNetworkBadge";
import {ChannelType} from "../../types/types";

const WhatsAppConnection: React.FC = () => {
    const {setHeaderContent} = useHeader();

    useEffect(() => {
        setHeaderContent('Добавить подключение');
    }, [setHeaderContent]);

    return (
        <div className="flex flex-col lg:flex-row justify-between">
            <ContentWrapper customClass="h-full lg:w-1/2 p-5">
                <h1 className="text-2xl text-center font-bold">
                    <SocialNetworkBadge network={ChannelType.WHATSAPP}/>
                </h1>
                <ol className="list-decimal pl-5 space-y-2 text-lg my-5">
                    <li>Нажмите на кнопку "Подключить WhatsApp" справа.</li>

                    <WhatsAppConnectionDescription/>

                    <li>После успешной авторизации, вы сможете управлять своим WhatsApp через наш сервис.</li>
                </ol>
                {/* WhatsAppConnectionSteps будет перед LostMessagesWarning на мобильных устройствах */}
                <div className="block lg:hidden p-5 shadow rounded-xl">
                    <WhatsAppConnectionSteps/>
                </div>
                <div className={`mt-10`}>
                    <LostMessagesWarning/>
                </div>
            </ContentWrapper>

            {/* Оригинальный блок для десктопов */}
            <div className="w-full lg:w-1/2 items-center justify-center pl-5 hidden lg:flex">
                <WhatsAppConnectionSteps/>
            </div>
        </div>
    );
};

export default WhatsAppConnection;
