import React from 'react';

interface ConnectionCardProps {
    type: string;
    description: string;
    icon: string;
    textColor?: string;
    onClick?: () => void;
}

const ConnectionCard: React.FC<ConnectionCardProps> = ({type, description, icon, textColor, onClick}) => {
    return (
        <div
            onClick={onClick}
            className="w-48 h-44 bg-white rounded-xl shadow-md p-5 flex flex-col justify-between transition-transform duration-300 cursor-pointer hover:transform hover:-translate-y-2 hover:shadow-lg">
            <div className="flex items-center">
                <i className={`fab ${icon} text-2xl mr-2 ${textColor}`}></i>
                <h3 className={`text-lg font-semibold ${textColor}`}>{type}</h3>
            </div>
            <p className="text-sm text-slate-600 leading-relaxed">{description}</p>
        </div>
    );
};

export default ConnectionCard;
