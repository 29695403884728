import React, {useCallback, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../redux";
import {cleanAttachments, pushMessage, setMessageText} from "../../../redux/slices/inbox-slice";
import UploadFileButton from "../../atom/UploadFileButton";
import {ChannelType, DirectionType} from "../../types/types";
import {clientWsAvitoChannel, clientWsTelegramChannel, clientWsWhatsappChannel} from "../../../api/client-ws";
import {FaCircleArrowUp} from "react-icons/fa6";
import SocialNetworkBadge from "../../atom/SocialNetworkBadge";
import ThemedButton from "../../atom/ThemedButton";

const TextAreaWithButtons: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const messageText = useSelector((state: RootState) => state.inbox.messageText) ?? '';
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const messages = useSelector((state: RootState) => state.inbox.messages);
    const selectedChatId = useSelector((state: RootState) => state.inbox.selectedChatId);
    const channelId = useSelector((state: RootState) => state.inbox.channelId);
    const channelType = useSelector((state: RootState) => state.inbox.channelType);
    const attachments = useSelector((state: RootState) => state.inbox.attachments);
    const useEnterToSend = useSelector((state: RootState) => state.inbox.useEnterToSend) ?? false;

    const getLastMessageChannelType = useCallback(() => {
        return messages.length > 0 ? messages[messages.length - 1].channelType : '';
    }, [messages]);

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const target = e.target;
        dispatch(setMessageText(target.value));

        // Автоматическое изменение высоты textarea
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    const handleSendMessage = async () => {
        if (!messageText.trim() && attachments.length === 0) return;
        if (!selectedChatId || !channelId) return;

        const sendMessage = async (text: string, attachmentId: string | null = null) => {
            const messageDto = {
                messageId: '',
                chatId: selectedChatId,
                body: text,
                channelId: channelId,
                channelType: channelType,
                directionType: DirectionType.OUTGOING,
                status: 'SENT',
                externalContactId: '',
                messageTimestamp: new Date().toISOString(),
                messageType: 'TEXT',
                sentByUserId: null,
                read: true,
                mediaFileId: attachmentId,
            };

            try {
                let response;
                if (channelType === ChannelType.WHATSAPP) {
                    response = await clientWsWhatsappChannel.sendMessage(channelId, messageDto);
                } else if (channelType === ChannelType.TELEGRAM) {
                    response = await clientWsTelegramChannel.sendMessage(channelId, messageDto);
                } else if (channelType === ChannelType.AVITO) {
                    response = await clientWsAvitoChannel.sendMessage(channelId, messageDto);
                }
                if (response) {
                    dispatch(pushMessage(messageDto));
                    dispatch(setMessageText(''));
                    dispatch(cleanAttachments());

                    // Сброс высоты textarea после отправки сообщения
                    if (textareaRef.current) {
                        textareaRef.current.style.height = 'auto';
                    }
                } else {
                    console.error(`Response is undefined for ${channelType}`);
                }
            } catch (error) {
                console.error(`Error sending to ${channelType}:`, error);
            }
        };

        if (channelType === ChannelType.TELEGRAM) {
            await sendMessage(messageText, attachments[0]?.fileId ?? null);
        } else {
            if (attachments.length > 0) {
                await sendMessage('', attachments[0]?.fileId);
            }
            if (messageText.trim()) {
                await sendMessage(messageText);
            }
        }
    };

    // Обработчик нажатий клавиш в textarea
    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (useEnterToSend) {
            if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey && !e.altKey && !e.metaKey) {
                e.preventDefault(); // Отправка сообщения при нажатии Enter
                handleSendMessage();
            }
            // При Shift + Enter происходит перенос строки
        } else {
            if (e.key === 'Enter' && e.ctrlKey) {
                e.preventDefault(); // Отправка сообщения при нажатии Ctrl + Enter
                handleSendMessage();
            }
            // При Enter происходит перенос строки
        }
    };


    return (
        <div
            className="w-full flex flex-col gap-1 p-2 pt-1 shadow space-y-2 rounded-lg hover:shadow-md2 focus-within:shadow-md2 transition-shadow">
            <textarea
                ref={textareaRef}
                value={messageText}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown} // Добавлен обработчик нажатия клавиш
                placeholder="Введите сообщение..."
                className="flex w-full max-h-[200px] px-1 rounded-t-lg resize-none focus:outline-none placeholder:text-slate-400"
                rows={1}
            />
            <div className="flex justify-between items-center bg-white rounded-b-lg">
                <UploadFileButton/>
                <div className="flex items-center">
                    {getLastMessageChannelType() && (
                        <button className="cursor-default">
                            <SocialNetworkBadge network={getLastMessageChannelType() ?? ''}
                                                short={false}
                                                inverted={true}
                                                background={false}
                                                border={true}
                                                hover={false}/>
                        </button>
                    )}
                    <ThemedButton
                        inverted={true}
                        backgroundInverted={true}
                        theme={getLastMessageChannelType() ?? ''}
                        onClick={handleSendMessage}
                    >
                        <FaCircleArrowUp/>
                    </ThemedButton>
                </div>
            </div>
        </div>
    );
};

export default TextAreaWithButtons;
