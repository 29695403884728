export interface ChatInterface {
    chatId: string;
    channelId: string;
    channelType: string;
    chatTitle: string;
    lastMessage: string;
    time: string;
    previewMessage: PreviewMessage;
}

export interface PreviewMessage {
    status: string,
    directionType: string,
    externalContactId: string,
    messageTimestamp: string,
    body: string,
    messageType: string,
    sentByUserId: null | string,
    read?: boolean;
    readByUserId?: string;
    readTimestamp?: string;
}

export interface Connection {
    channelId: string; // connection на бэкенде назвается channel
    channelType: string;
    title?: string;
    status: ConnectionStatus;
}

export interface MessageInterface {
    messageId: string;
    chatId: string;
    body: string;
    channelId: string | null;
    channelType: string | null;
    directionType: string;
    status: string;
    externalContactId: string;
    messageTimestamp: string;
    messageType: string;
    sentByUserId: null;
    read?: boolean;
    readByUserId?: string;
    readTimestamp?: string;
    mediaFileId: string | null;
}

export interface User {
    id: string;
    name: string;
    email: string;
}

export const enum ChannelType {
    WHATSAPP = "WHATSAPP",
    TELEGRAM = "TELEGRAM",
    AVITO = "AVITO",
    FACEBOOK = "FACEBOOK",
    INSTAGRAM = "INSTAGRAM",
    VK = "VK",
}

export const enum DirectionType {
    INCOMING = "INCOMING",
    OUTGOING = "OUTGOING",
}

export const enum RoleType {
    OWNER = "OWNER",
    TEAMMATE = "TEAMMATE",
}

export const enum StatusType {
    ACTIVE = "ACTIVE",
}

export enum ConnectionStatus {
    INIT = 'INIT',
    ACTIVE = 'ACTIVE',
    SESSION_TERMINATED = 'SESSION_TERMINATED',
    SESSION_ERROR = 'SESSION_ERROR',
}


export const LIMITS = {
    WHATSAPP: 1,
    TELEGRAM: 1,
    AVITO: 1,
    TEAMMATES: 9999,
    FILES: 1,
};

export type AuthStatus = 'INIT' | 'ACTIVE' | 'ERROR'; // TODO переделать на enum

export const enum ModalType {
    EditConnection,
    DeleteConnection,
    ForceSyncContacts,
    TeammatesCreation,
    InboxSettings,
    ConnectionStatus
}

export interface AuthLoginRequest {
    email: string;
    password: string;
    rememberMe: boolean;
}

export interface AuthSignupRequest {
    email: string;
    password: string;
}

export interface TeammatesResponse {
    "userId": string,
    "fullName": string,
    "email": string,
    "registrationDate": string,
}

export interface InvitationLinkResponse {
    "invitationLink": string,
    "created": string,
    "inviteCode": string
}

export interface TeammateWithInvite {
    "teammate"?: TeammatesResponse,
    "invitationLink"?: InvitationLinkResponse,
}

export interface ErrorResponse {
    "status": string,
    "code": string,
    "message": string,
    "errorDetails": string,
    "path": string,
    "timestamp": string,
}

export interface AttachmentResponse {
    contentType: string, // "image/png"
    created: string, //"2024-08-07T13:09:03.562Z"
    fileId: string, // "66b371efed4f2d766e4955d4"
    link: string, // "https://dev.chatholder.ru/66b371efed4f2d766e4955d4"
    name: string, //"photo.png"
    previewData: string, // null
    size: number, //7856
}