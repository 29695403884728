import instance from './base-api';
import {Connection, MessageInterface} from "../components/types/types";

// Общий базовый URL для всех запросов
const apiUrl = '/client-ws';

// Whatsapp Channel Resource
const clientWsWhatsappChannel = {
    get: (channelId: string) => instance.get(`${apiUrl}/api/whatsapp-channels/${channelId}`),
    update: (channelId: string, channelDto: Connection) => instance.put(`${apiUrl}/api/whatsapp-channels/${channelId}`, channelDto),
    delete: (channelDto: Connection) => instance.delete(`${apiUrl}/api/whatsapp-channels/${channelDto.channelId}`),
    create: () => instance.post(`${apiUrl}/api/whatsapp-channels`, {}),
    syncContactsAndMessages: (channelId: string) => instance.post(`${apiUrl}/api/whatsapp-channels/${channelId}/sync`, {}),
    sendMessage: (channelId: string, messageDto: MessageInterface) => instance.post(`${apiUrl}/api/whatsapp-channels/${channelId}/send-message`, messageDto),
    init: (channelId: string) => instance.post(`${apiUrl}/api/whatsapp-channels/${channelId}/init`, {}),
    disable: (channelDto: Connection) => instance.post(`${apiUrl}/api/whatsapp-channels/${channelDto.channelId}/disable`, channelDto),
    getAuthQrCode: (channelId: string) => instance.get(`${apiUrl}/api/whatsapp-channels/${channelId}/qr-code`),
};

// Telegram Channel Resource
const clientWsTelegramChannel = {
    get: (channelId: string) => instance.get(`${apiUrl}/api/telegram-channels/${channelId}`),
    update: (channelId: string, channelDto: Connection) => instance.put(`${apiUrl}/api/telegram-channels/${channelId}`, channelDto),
    delete: (channelDto: Connection) => instance.delete(`${apiUrl}/api/telegram-channels/${channelDto.channelId}`),
    create: () => instance.post(`${apiUrl}/api/telegram-channels`, {}),
    syncContactsAndMessages: (channelId: string) => instance.post(`${apiUrl}/api/telegram-channels/${channelId}/sync`, {}),
    sendMessage: (channelId: string, messageDto: MessageInterface) => instance.post(`${apiUrl}/api/telegram-channels/${channelId}/send-message`, messageDto),
    init: (channelId: string) => instance.post(`${apiUrl}/api/telegram-channels/${channelId}/init`, {}),
    disable: (channelDto: Connection) => instance.post(`${apiUrl}/api/telegram-channels/${channelDto.channelId}/disable`, channelDto),
    getAuthQrCode: (channelId: string) => instance.get(`${apiUrl}/api/telegram-channels/${channelId}/qr-code`),
};

// Avito Channel Resource
const clientWsAvitoChannel = {
    get: (channelId: string) => instance.get(`${apiUrl}/api/avito-channels/${channelId}`),
    update: (channelId: string, channelDto: Connection) => instance.put(`${apiUrl}/api/avito-channels/${channelId}`, channelDto),
    delete: (channelDto: Connection) => instance.delete(`${apiUrl}/api/avito-channels/${channelDto.channelId}`),
    create: () => instance.post(`${apiUrl}/api/avito-channels`, {}),
    syncContactsAndMessages: (channelId: string) => instance.post(`${apiUrl}/api/avito-channels/${channelId}/sync`, {}),
    sendMessage: (channelId: string, messageDto: MessageInterface) => instance.post(`${apiUrl}/api/avito-channels/${channelId}/send-message`, messageDto),
    init: (channelId: string) => instance.post(`${apiUrl}/api/avito-channels/${channelId}/init`, {}),
    disable: (channelDto: Connection) => instance.post(`${apiUrl}/api/avito-channels/${channelDto.channelId}/disable`, channelDto),
    getAuthUrl: (channelId: string) => instance.get(`${apiUrl}/api/avito-channels/${channelId}/login-url`),
    getAuthRedirectUrl: (channelId: string) => instance.get(`${apiUrl}/api/avito-channels/${channelId}/login-url-redirect`),
};

// User Profile Resource
const clientWsUserProfile = {
    load: () => instance.get(`${apiUrl}/api/user-profile`),
    updateSettingsProperty: (settings: any) => instance.put(`${apiUrl}/api/user-profile/settings-property`, settings),
    updateUserProfileSettings: (profileSettings: any) => instance.put(`${apiUrl}/api/user-profile/settings`, profileSettings),
}

// User Invitation Resource
const clientWsUserInvitation = {
    create: () => instance.post(`${apiUrl}/api/user/invitation/create-invite-code`),
    get: () => instance.get(`${apiUrl}/api/user/invitation/all`),
    delete: (inviteCode: string) => instance.delete(`${apiUrl}/api/user/invitation/delete/${inviteCode}`),
};

// Content Upload Resource
const clientWsContentUpload = {
    upload: (fileData: FormData) => {
        return instance.post(`${apiUrl}/api/upload/upload`, fileData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
};

// Multi Users Resource
const clientWsMultiUsers = {
    get: () => instance.get(`${apiUrl}/api/user/multi/all`),
};

// Message Operation Resource
const clientWsMessageOperate = {
    markAsRead: (messageId: string) => instance.post(`${apiUrl}/api/messages-operate/${messageId}/mark-as-read`),
    markAsUnread: (messageId: string) => instance.post(`${apiUrl}/api/messages-operate/${messageId}/mark-as-unread`),
};

// Messages and Chats Resource
const clientWsChat = {
    find: (page: number = 0, size: number = 50, channelId?: string, onlyUnread?: boolean) =>
        instance.get(`${apiUrl}/api/chats?page=${page}&size=${size}${channelId ? `&channelId=${encodeURIComponent(channelId)}` : ''}${onlyUnread ? `&onlyUnread=true` : ''}`),
    markAsRead: (chatId: string) => instance.post(`${apiUrl}/api/chats/${chatId}/mark-as-read`),
};

const clientWsMessage = {
    find: (chatId: string, page: number = 0, size: number = 50) => instance.get(`${apiUrl}/api/messages/${chatId}?page=${page}&size=${size}`),
};

// Content-resource
const clientWsContent = {
    findFilesByIds: (ids: string[]) => instance.get(`${apiUrl}/api/files`, {params: {ids}}),
    getFilePreview: (fileId: string) => instance.get(`${apiUrl}/api/files/${fileId}/preview`, {responseType: 'blob'}),
    getFileContent: (fileId: string) => instance.get(`${apiUrl}/api/files/${fileId}/content`, {responseType: 'blob'}),
};

const clientWsChannel = {
    getAll: () => instance.get(`${apiUrl}/api/channels`),
};

export {
    clientWsWhatsappChannel,
    clientWsTelegramChannel,
    clientWsAvitoChannel,
    clientWsUserProfile,
    clientWsUserInvitation,
    clientWsContentUpload,
    clientWsMultiUsers,
    clientWsMessageOperate,
    clientWsChat,
    clientWsMessage,
    clientWsContent,
    clientWsChannel
};
