import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useHeader} from '../../context/HeaderContext';
import ConnectionsDeleteModal from '../molecula/connections/ConnectionsDeleteModal';
import ConnectionsTable from '../molecula/connections/ConnectionsTable';
import ForceSyncModal from '../molecula/connections/ConnectionsForceSyncContactsModal';
import {Connection, ModalType} from "../types/types";
import {setConnection, setConnections,} from "../../redux/slices/connections-slice";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../redux";
import ContentWrapper from "../atom/ContentWrapper";
import ConnectionsEditModal from "../molecula/connections/ConnectionsEditModal";
import {clientWsChannel} from "../../api/client-ws";
import {setTypeOfModalToBeShown} from "../../redux/slices/common-slice";
import Button, {ButtonColor, ButtonSize} from "../atom/Button";
import ConnectionsStatusModal from "../molecula/connections/ConnectionsStatusModal";


const Connections: React.FC = () => {
    const {setHeaderContent} = useHeader();
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        setHeaderContent('Подключения');
        fetchConnections();
        // eslint-disable-next-line
    }, [setHeaderContent]);

    const fetchConnections = async () => {
        try {
            const response = await clientWsChannel.getAll();
            dispatch(setConnections(response.data))
        } catch (error) {
            console.error('Error fetching connections:', error);
        }
    };

    const handleEditConnection = (connection: Connection) => {
        dispatch(setTypeOfModalToBeShown(ModalType.EditConnection))
        dispatch(setConnection(connection));
    };

    const handleDeleteConnection = (connection: Connection) => {
        dispatch(setTypeOfModalToBeShown(ModalType.DeleteConnection))
        dispatch(setConnection(connection));
    };

    return (
        <ContentWrapper>
            <header className="h-16 flex items-center justify-start">
                <div className={`ml-2`}>
                    <Link to="/connections/add-connection">
                        <Button title={"Добавить подключение"} buttonColor={ButtonColor.GREEN}
                                customClass={`shadow`} buttonSize={ButtonSize.FULL}/>
                    </Link>
                </div>
            </header>
            <div className="flex-1 overflow-y-auto">
                <ConnectionsTable onDelete={handleDeleteConnection}
                                  onEdit={handleEditConnection}/>
            </div>
            <ConnectionsEditModal/>
            <ConnectionsDeleteModal/>
            <ForceSyncModal/>
            <ConnectionsStatusModal/>
        </ContentWrapper>
    );
};

export default Connections;
