import React from 'react';
import {ConnectionStatus, ModalType} from "../../types/types";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../redux";
import {setTypeOfModalToBeShown} from "../../../redux/slices/common-slice";
import ModalWrapper from "../../atom/ModalWrapper";
import StatusBadge from "../../atom/StatusBadge";

const statusDescriptions = {
    [ConnectionStatus.ACTIVE]: 'Соединение активно и работает корректно',
    [ConnectionStatus.INIT]: 'Устанавливается связь и начинается синхронизация чатов',
    [ConnectionStatus.SESSION_TERMINATED]: 'Соединение закрыто, для возобновления требуется повторное подключение',
    [ConnectionStatus.SESSION_ERROR]: 'Произошла ошибка в подключении',
};

const ConnectionStatusModal: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const typeOfModalToBeShown = useSelector((state: RootState) => state.common.typeOfModalToBeShown);

    const onRequestClose = () => {
        dispatch(setTypeOfModalToBeShown(null));
    };

    return (
        <ModalWrapper
            isOpen={typeOfModalToBeShown === ModalType.ConnectionStatus}
            onRequestClose={onRequestClose}
            contentLabel="Статусы соединений"
            okButtonText="Закрыть"
            onOkButtonClick={onRequestClose}
        >
            <div className="flex flex-col space-y-4">
                {Object.entries(ConnectionStatus).map(([statusKey, status]) => (
                    <div key={statusKey} className="flex items-center space-x-2">
                        <div className="w-32">
                            <StatusBadge status={status} short={false} border={false} background={true}
                                         className={`w-full shadow text-sm cursor-default`}/>
                        </div>
                        <div className="text-sm text-gray-600 flex-1">{statusDescriptions[status]}</div>
                    </div>
                ))}
            </div>
        </ModalWrapper>
    );
};

export default ConnectionStatusModal;
