import React, {useEffect} from 'react';

interface LoginByUrlStepProps {
    loginUrl: string | null;
}

const LoginByUrlStep: React.FC<LoginByUrlStepProps> = ({loginUrl}) => {
    useEffect(() => {
        if (loginUrl) {
            window.location.href = loginUrl;
        }
    }, [loginUrl]);

    return <div className="animate-fadeInOut text-2xl text-slate-800 mb-5 w-full flex justify-center">Redirecting to
        login...</div>;
};

export default LoginByUrlStep;
