import React from 'react';
import './AnimatedCheckmark.css';

const AnimatedCheckmark: React.FC = () => {
    return (
        <div>
            <svg
                className="checkmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
            >
                <path d="M20 6L9 17l-5-5"/>
            </svg>
        </div>
    );
};

export default AnimatedCheckmark;
