import React, {useEffect} from 'react';
import {useHeader} from '../../context/HeaderContext';
import ContentWrapper from '../atom/ContentWrapper';
import TeammatesCreationModal from "../molecula/team/TeammatesCreationModal";
import {AppDispatch} from "../../redux";
import {useDispatch} from "react-redux";
import {setTypeOfModalToBeShown} from "../../redux/slices/common-slice";
import {ModalType} from "../types/types";
import TeammatesTable from "../molecula/team/TeammatesTable";
import Button, {ButtonColor, ButtonSize} from "../atom/Button";

const Team: React.FC = () => {
    const {setHeaderContent} = useHeader();
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        setHeaderContent('Команда');
    }, [setHeaderContent]);

    const handleCreateTeammate = () => {
        dispatch(setTypeOfModalToBeShown(ModalType.TeammatesCreation))
    };

    return (
        <ContentWrapper>
            <header className="h-16 flex items-center justify-start">
                <div className={`ml-2`}>
                    <Button title={"Создать оператора"} buttonSize={ButtonSize.FULL} buttonColor={ButtonColor.GREEN}
                            customClass={`shadow`} onClick={handleCreateTeammate}/>
                </div>
            </header>

            <TeammatesTable/>

            <TeammatesCreationModal/>
        </ContentWrapper>
    );
};

export default Team;
