import React, {ReactNode} from 'react';
import {Navigate} from 'react-router-dom';
import {useAuth} from '../context/AuthContext';
import {RoleType} from "../components/types/types";
import {useProfileHelper} from "../hooks/useProfileHelper";

export const PrivateRoute: React.FC<{ children: ReactNode, requiredRole?: RoleType }> = ({children, requiredRole}) => {
    const {loadProfileData, getRole} = useProfileHelper();

    const {userIsAuthenticated} = useAuth();

    if (userIsAuthenticated()) {
        // Перезагружаем профиль пользователя, на случай если он сменил его на клиенте
        if (requiredRole) {
            loadProfileData().catch((error) => {
                console.error("Ошибка загрузки профиля:", error);
            });
        }

        if (requiredRole && getRole() !== requiredRole) {
            return <Navigate to="/dashboard"/>;  // Перенаправляем на страницу без доступа
        }

        return <>{children}</>;
    }

    return <Navigate to="/login"/>;
};

export default PrivateRoute;
