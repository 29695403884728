import React, {useEffect, useState} from 'react';

const CookieBanner: React.FC = () => {
    const [showBanner, setShowBanner] = useState<boolean>(false);

    useEffect(() => {
        const cookieConsent = localStorage.getItem('cookieConsent');
        if (!cookieConsent) {
            setShowBanner(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'true');
        setShowBanner(false);
    };

    if (!showBanner) {
        return null;
    }

    return (
        <div className="fixed bottom-0 w-full bg-slate-800 text-white p-4 text-center">
            Для работы сайта мы используем файлы <a
            href="https://docs.google.com/document/d/1FpGktbePTmIjy3kfMeENwqN99GdxTVqG"
            className="text-blue-400 hover:text-blue-300 underline"
            target="_blank" rel="noopener noreferrer"
        >cookie</a>.
            <button
                onClick={handleAccept}
                className="ml-4 bg-slate-700 hover:bg-slate-600 text-white py-2 px-4 rounded"
            >
                Принять
            </button>
        </div>
    );
};

export default CookieBanner;
