import React, {useCallback, useEffect, useState} from 'react';
import SuccessStep from "../SuccessStep";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux";
import {clientWsAvitoChannel} from "../../../../api/client-ws";
import ButtonStep from "../ButtonStep";
import {AuthStatus} from "../../../types/types";
import LoginByUrlStep from "../LoginByUrlStep";

const AvitoConnectionSteps: React.FC = () => {
    const [authUrl, setAuthUrl] = useState<string | null>(null);
    const [authStatus, setAuthStatus] = useState<AuthStatus>('INIT');

    const newConnectionId = useSelector((state: RootState) => state.connections.newConnectionId);

    const fetchAuthUrl = useCallback(async () => {
        if (!newConnectionId || authStatus !== 'INIT') return;
        try {
            const response = await clientWsAvitoChannel.getAuthUrl(newConnectionId);
            setAuthUrl(response.data.url); // Заменяем qrCode на loginUrl
        } catch (error) {
            console.error('Error getting Avito auth url:', error);
        }
    }, [newConnectionId, authStatus]);

    const checkAuthStatus = useCallback(async () => {
        if (!newConnectionId) return;
        try {
            const response = await clientWsAvitoChannel.get(newConnectionId);
            setAuthStatus(response.data.status as AuthStatus);
        } catch (error) {
            console.error('Error checking Avito channel status:', error);
        }
    }, [newConnectionId]);

    useEffect(() => {
        if (newConnectionId && authStatus !== 'ACTIVE') {
            fetchAuthUrl(); // Однократное получение URL
        }
    }, [newConnectionId, authStatus, fetchAuthUrl]);

    useEffect(() => {
        if (authUrl !== null && authStatus === 'INIT') {
            const authStatusInterval = setInterval(checkAuthStatus, 10000); // Интервал для проверки статуса
            return () => clearInterval(authStatusInterval);
        }
    }, [authUrl, authStatus, checkAuthStatus]);

    if (authStatus === 'ACTIVE') {
        return <SuccessStep/>;
    }

    return (
        <>
            {!newConnectionId && <ButtonStep buttonName={"Подключить Avito"}/>}
            {newConnectionId && <LoginByUrlStep loginUrl={authUrl}/>}
        </>
    );
};

export default AvitoConnectionSteps;
