import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../../../redux';
import {ChatInterface, DirectionType} from "../../types/types";
import {setChannelId, setChannelType, setSelectedChat, setSelectedChatId} from '../../../redux/slices/inbox-slice';
import SocialNetworkBadge from "../../atom/SocialNetworkBadge";
import {formatDate} from "../../../utils/dateUtils";

interface ChatListItemProps {
    chat: ChatInterface;
}

const Chat: React.FC<ChatListItemProps> = ({chat}) => {
    const dispatch: AppDispatch = useDispatch();
    const activeChatId = useSelector((state: RootState) => state.inbox.selectedChatId);

    const handleClick = () => {
        dispatch(setSelectedChat(chat));
        dispatch(setSelectedChatId(chat.chatId));
        dispatch(setChannelId(chat.channelId));
        dispatch(setChannelType(chat.channelType));
    };

    const isActive = chat.chatId === activeChatId;
    const previewMessage = chat.previewMessage?.body || 'No preview available';

    const chatPreviewClass = chat.previewMessage && !chat.previewMessage.read && chat.previewMessage.directionType === DirectionType.INCOMING
        ? 'font-medium text-slate-800'
        : 'text-slate-400';

    const chatTitleClass = chat.previewMessage && !chat.previewMessage.read && chat.previewMessage.directionType === DirectionType.INCOMING
        ? 'font-semibold text-slate-800'
        : 'text-slate-700';

    const messageTimeClass = chat.previewMessage && !chat.previewMessage.read && chat.previewMessage.directionType === DirectionType.INCOMING
        ? 'text-orange-600'
        : 'text-slate-500';

    return (
        <div
            className={`cursor-pointer transition duration-300 ease-in-out p-3 pr-4 ${isActive ? 'bg-slate-200' : 'hover:bg-slate-50'}`}
            onClick={handleClick}
        >
            <div className="flex items-center">
                <div className=" flex-1 flex flex-col justify-between min-w-0">
                    <div className="flex justify-between items-center">
                        <div className="overflow-hidden min-w-0">
                            <SocialNetworkBadge network={chat.channelType} short={true} inverted={true}
                                                background={true} border={false} hover={false}/>
                        </div>
                        <div className="flex-1 ml-2 overflow-hidden min-w-0">
                            <div className={`font-semibold text-sm ${chatTitleClass} truncate`}>
                                {chat.chatTitle}
                            </div>
                        </div>
                        {chat.previewMessage && !chat.previewMessage.read && chat.previewMessage.directionType === DirectionType.INCOMING && (
                            <div className={`relative`}>
                                <span
                                    className="absolute flex-shrink-0 animate-ping ml-4 mr-0.5 text-xs bg-orange-600 rounded-full w-3 h-3 flex items-center justify-center">
                                </span>
                                <span
                                    className="relative flex-shrink-0 ml-4 mr-0.5 text-xs bg-orange-600 rounded-full w-3 h-3 flex items-center justify-center">
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="flex justify-between items-center mt-1">
                        <div className={`flex-1 min-w-0 ml-1 text-xs truncate ${chatPreviewClass} overflow-hidden`}>
                            {previewMessage}
                        </div>

                        {chat.previewMessage && (
                            <div className={`flex-shrink-0 text-xs ${messageTimeClass} ml-2 whitespace-nowrap`}>
                                {formatDate(chat.previewMessage.messageTimestamp)}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Chat;
