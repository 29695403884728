import React, {ChangeEvent, useEffect, useState} from 'react';
import {ChannelType, ModalType} from "../../types/types";
import {AppDispatch, RootState} from "../../../redux";
import {useDispatch, useSelector} from "react-redux";
import {clientWsAvitoChannel, clientWsTelegramChannel, clientWsWhatsappChannel} from "../../../api/client-ws";
import {setTypeOfModalToBeShown} from "../../../redux/slices/common-slice";
import {deleteConnection} from "../../../redux/slices/connections-slice";
import ModalWrapper from "../../atom/ModalWrapper";
import CheckboxField from "../../atom/ChekboxField";

const ConnectionsDeleteModal: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const typeOfModalToBeShown = useSelector((state: RootState) => state.common.typeOfModalToBeShown);
    const connection = useSelector((state: RootState) => state.connections.connection);
    const [isConfirmed, setIsConfirmed] = useState(false);

    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIsConfirmed(e.target.checked);
    };

    const onRequestClose = () => {
        dispatch(setTypeOfModalToBeShown(null));
        setIsConfirmed(false);
    };

    const handleSubmit = async () => {
        if (isConfirmed && connection) {
            try {
                if (connection.channelType === ChannelType.WHATSAPP) {
                    await clientWsWhatsappChannel.delete(connection);
                } else if (connection.channelType === ChannelType.TELEGRAM) {
                    await clientWsTelegramChannel.delete(connection);
                } else if (connection.channelType === ChannelType.AVITO) {
                    await clientWsAvitoChannel.delete(connection);
                }
                dispatch(deleteConnection(connection.channelId));
                onRequestClose();
            } catch (error) {
                console.error(`Ошибка при удалении канала ${connection.channelType}:`, error);
            }
        }
    };

    useEffect(() => {
        if (!connection) setIsConfirmed(false);
    }, [connection]);

    return connection ? (
        <ModalWrapper
            isOpen={typeOfModalToBeShown === ModalType.DeleteConnection}
            onRequestClose={onRequestClose}
            contentLabel="Подтвержение удаления"
            badge={connection.channelType}
            okButtonText="Удалить"
            cancelButtonText="Отмена"
            onOkButtonClick={handleSubmit}
            okButtonDisabled={!isConfirmed}
        >
            <div className="bg-red-100 text-red-600 p-2.5 rounded-lg mb-5">
                Внимание. Удаление подключения "{connection.title}" необратимо.
            </div>
            <div className="text-xs bg-blue-100 text-blue-600 p-2.5 rounded-lg mb-5">
                ID подключения: {connection.channelId}
            </div>
            <CheckboxField
                name="rememberMe"
                checked={isConfirmed}
                onChange={handleCheckboxChange}
                label="Я соглашаюсь, что удаление выбранного подключения приведет к удалению из ChatHolder и это действие
                        не может быть отменено."
            />
        </ModalWrapper>
    ) : null;
};

export default ConnectionsDeleteModal;
