import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createRoot} from "react-dom/client";

// Указываем, что элемент с ID 'root' может быть null, и проверяем его наличие перед использованием
const rootElement = document.getElementById("root");

if (!rootElement) throw new Error('Failed to find the root element');
const root = createRoot(rootElement);

root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);

// Если вы хотите начать измерение производительности в вашем приложении, передайте функцию
// для записи результатов (например: reportWebVitals(console.log))
// или отправьте на аналитическую конечную точку. Узнайте больше: https://bit.ly/CRA-vitals
reportWebVitals();
