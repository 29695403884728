import React from 'react';

interface ErrorMessageProps {
    message: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({message}) => {
    return (
        <div className="absolute mt-1 text-sm left-1/2 text-nowrap transform -translate-x-1/2  text-red-500">
            {message}
        </div>
    );

};

export default ErrorMessage;
