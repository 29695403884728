import React from 'react';
import {getNetworkStyles} from "../../utils/styleUtils";

interface ButtonProps {
    theme: string;
    onClick: () => void;
    children: React.ReactNode;
    inverted?: boolean;
    backgroundInverted?: boolean;
    disabled?: boolean;
}

const ThemedButton: React.FC<ButtonProps> = ({
                                                 theme,
                                                 inverted = false, backgroundInverted = false,
                                                 onClick, disabled, children
                                             }) => {
    const {badgeClass} = getNetworkStyles(theme, inverted, backgroundInverted);

    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={`p-2 rounded transition duration-300 font-bold ${badgeClass}`}
        >
            {children}
        </button>
    );
};

export default ThemedButton;