import React, {useEffect, useState} from 'react';
import {LIMITS, ModalType} from "../../types/types";
import {AppDispatch, RootState} from "../../../redux";
import {useDispatch, useSelector} from "react-redux";
import {setTypeOfModalToBeShown} from "../../../redux/slices/common-slice";
import {clientWsMultiUsers, clientWsUserInvitation} from "../../../api/client-ws";
import {FaUsers} from 'react-icons/fa';
import ModalWrapper from "../../atom/ModalWrapper";

const TeammatesCreationModal: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const typeOfModalToBeShown = useSelector((state: RootState) => state.common.typeOfModalToBeShown);
    const [isLimitExceeded, setIsLimitExceeded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [usersCount, setUsersCount] = useState(0);

    useEffect(() => {
        const fetchUserCount = async () => {
            try {
                const users = await clientWsMultiUsers.get();
                setUsersCount(users.data.length);
                setIsLimitExceeded(users.data.length >= LIMITS.TEAMMATES);
            } catch (error) {
                console.error("Ошибка при получении пользователей:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserCount();
    }, []);

    const onRequestClose = () => {
        dispatch(setTypeOfModalToBeShown(null));
    };

    const handleCreate = () => {
        if (!isLimitExceeded) {
            clientWsUserInvitation.create();
            onRequestClose();
        }
    };

    return (
        <ModalWrapper
            isOpen={typeOfModalToBeShown === ModalType.TeammatesCreation}
            onRequestClose={onRequestClose}
            contentLabel="Создание оператора"
            okButtonText="Создать"
            cancelButtonText="Отмена"
            onOkButtonClick={handleCreate}
            okButtonDisabled={isLimitExceeded || isLoading}
        >
            <p className="text-slate-600 mb-6">
                После создания оператора будет сгенерирован инвайт-код. Вы можете передать этот код пользователю для
                регистрации,
                или отправить ему прямую ссылку, которая автоматически заполнит этот код на странице регистрации
                операторов.
            </p>
            <div className="mb-6 flex items-center space-x-2">
                <FaUsers className="text-slate-700"/>
                <p className="text-slate-700 font-semibold">Лимит на создание учетных записей:</p>
                <span className="text-slate-700 font-medium">{usersCount} / {LIMITS.TEAMMATES}</span>
                <div className="flex-grow h-2 bg-slate-300 rounded-full overflow-hidden ml-2">
                    <div
                        className={`h-full ${usersCount >= LIMITS.TEAMMATES ? 'bg-red-500' : 'bg-blue-500'}`}
                        style={{width: `${(usersCount / LIMITS.TEAMMATES) * 100}%`}}
                    />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default TeammatesCreationModal;
