import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface SidebarState {
    sidebarButtonVisible?: boolean;
    sidebarVisible?: boolean;
}

const initialState: SidebarState = {
    sidebarButtonVisible: false,
    sidebarVisible: false,
};

const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        setSidebarButtonVisible(state, action: PayloadAction<boolean>) {
            state.sidebarButtonVisible = action.payload;
        },
        setSidebarVisible(state, action: PayloadAction<boolean>) {
            state.sidebarVisible = action.payload;
        },
    },
});

export const {
    setSidebarButtonVisible,
    setSidebarVisible
} = sidebarSlice.actions;

export default sidebarSlice.reducer;
