import React from 'react';
import SocialNetworkBadge from '../../atom/SocialNetworkBadge';
import ConnectionsRowMenu from './ConnectionsRowMenu';
import StatusBadge from '../../atom/StatusBadge';
import ForceSyncButton from '../../atom/ForceSyncButton';
import {Connection, ConnectionStatus} from "../../types/types";

interface ConnectionsTableRowProps {
    connection: Connection;
    onEdit: (connection: Connection) => void;
    onDelete: (connection: Connection) => void;
}

const ConnectionsTableRow: React.FC<ConnectionsTableRowProps> = ({connection, onEdit, onDelete}) => {
    return (
        <tr
            className="hover:bg-table-bg-hover cursor-pointer border-b border-slate-300"
            onClick={() => onEdit(connection)}
        >
            <td className="py-2 px-4"></td>
            <td className="py-2 px-4">{connection.title ? connection.title : connection.channelId}</td>
            <td className="py-2 px-4"><SocialNetworkBadge network={connection.channelType} short={false} border={false}
                                                          inverted={false} background={true} hover={false}/></td>
            <td className="py-2 px-4"><StatusBadge status={connection.status as ConnectionStatus} background={true}
                                                   border={false} className={`shadow`}/></td>
            <td className="py-2 px-4"></td>
            <td className="py-2 px-4">
                <div className="flex space-x-0.5">
                    <ForceSyncButton connection={connection}/>
                    <ConnectionsRowMenu
                        onEdit={() => onEdit(connection)}
                        onDelete={() => onDelete(connection)}
                    />
                </div>
            </td>
        </tr>
    );
};

export default ConnectionsTableRow;
