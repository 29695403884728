import React from 'react';
import {ConnectionStatus} from '../types/types';

interface StatusBadgeProps {
    status: ConnectionStatus;
    short?: boolean;
    rounded?: boolean;
    invertColor?: boolean;
    border?: boolean;
    background?: boolean;
    className?: string;
}

// Словарь для русских текстов статусов
const statusTexts: Record<ConnectionStatus, string> = {
    [ConnectionStatus.ACTIVE]: 'Активно',
    [ConnectionStatus.INIT]: 'Подключение',
    [ConnectionStatus.SESSION_TERMINATED]: 'Завершено',
    [ConnectionStatus.SESSION_ERROR]: 'Ошибка',
};

const StatusBadge: React.FC<StatusBadgeProps> = ({
                                                     status,
                                                     short = false,
                                                     rounded = false,
                                                     invertColor = false,
                                                     border = false,
                                                     background = true,
                                                     className = ''
                                                 }) => {
    const baseClasses = invertColor
        ? {
            [ConnectionStatus.ACTIVE]: `${background ? 'bg-status-badge-active-text' : ''} text-status-badge-active-bg`,
            [ConnectionStatus.INIT]: `${background ? 'bg-status-badge-init-text' : ''} text-status-badge-init-bg`,
            [ConnectionStatus.SESSION_TERMINATED]: `${background ? 'bg-status-badge-session-terminated-text' : ''} text-status-badge-session-terminated-bg`,
            [ConnectionStatus.SESSION_ERROR]: `${background ? 'bg-status-badge-session-error-text' : ''} text-status-badge-session-error-bg`,
        }
        : {
            [ConnectionStatus.ACTIVE]: `${background ? 'bg-status-badge-active-bg' : ''} text-status-badge-active-text`,
            [ConnectionStatus.INIT]: `${background ? 'bg-status-badge-init-bg' : ''} text-status-badge-init-text`,
            [ConnectionStatus.SESSION_TERMINATED]: `${background ? 'bg-status-badge-session-terminated-bg' : ''} text-status-badge-session-terminated-text`,
            [ConnectionStatus.SESSION_ERROR]: `${background ? 'bg-status-badge-session-error-bg' : ''} text-status-badge-session-error-text`,
        };

    const statusClass = baseClasses[status];

    const borderColorClass = border ? (() => {
        switch (status) {
            case ConnectionStatus.ACTIVE:
                return 'border-status-badge-active-text';
            case ConnectionStatus.INIT:
                return 'border-status-badge-init-text';
            case ConnectionStatus.SESSION_TERMINATED:
                return 'border-status-badge-session-terminated-text';
            case ConnectionStatus.SESSION_ERROR:
                return 'border-status-badge-session-error-text';
            default:
                return 'border-status-badge-unknown-text';
        }
    })() : '';

    return (
        <div
            className={`inline-block font-bold text-center text-nowrap py-1 ${rounded ? 'rounded-full px-2' : 'rounded-md px-3'} ${statusClass} ${short ? 'text-center w-10' : ''} ${border ? `border ${borderColorClass}` : ''} ${className}`}>
            {short ? statusTexts[status].charAt(0) : statusTexts[status]}
        </div>
    );
};

export default StatusBadge;
