import React, {useCallback, useEffect, useState} from 'react';
import SuccessStep from '../SuccessStep';
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux";
import {clientWsTelegramChannel} from "../../../../api/client-ws";
import ButtonStep from "../ButtonStep";
import QrCodeStep from "../QrCodeStep";

interface TelegramChannelResponse {
    data: {
        id: string;
        status: string;
        qrCode?: string;
    };
}

const TelegramConnectionSteps: React.FC = () => {
    const [qrCode, setQrCode] = useState<string | null>(null);
    const [authStatus, setAuthStatus] = useState<string>('INIT');

    const newConnectionId = useSelector((state: RootState) => state.connections.newConnectionId);


    const showQrCodeHandler = useCallback(async () => {
        if (!newConnectionId || authStatus !== 'INIT') return;
        try {
            const response: TelegramChannelResponse = await clientWsTelegramChannel.getAuthQrCode(newConnectionId);
            setQrCode(response.data.qrCode || '');
        } catch (error) {
            console.error('Error getting Telegram QR code:', error);
        }
    }, [newConnectionId, authStatus]);

    const checkAuthStatus = useCallback(async () => {
        if (!newConnectionId) return;
        try {
            const response: TelegramChannelResponse = await clientWsTelegramChannel.get(newConnectionId);
            setAuthStatus(response.data.status);
        } catch (error) {
            console.error('Error checking Telegram channel status:', error);
        }
    }, [newConnectionId]);

    useEffect(() => {
        if (newConnectionId && authStatus !== 'ACTIVE') {
            const qrCodeInterval = setInterval(showQrCodeHandler, 5000);
            return () => clearInterval(qrCodeInterval);
        }
    }, [newConnectionId, authStatus, showQrCodeHandler]);

    useEffect(() => {
        if (qrCode !== null && authStatus === 'INIT') {
            const authStatusInterval = setInterval(checkAuthStatus, 2000);
            return () => clearInterval(authStatusInterval);
        }
    }, [qrCode, authStatus, checkAuthStatus]);

    if (authStatus === 'ACTIVE') {
        return <SuccessStep/>;
    }

    return (
        <>
            {!newConnectionId && <ButtonStep buttonName={`Подключить телеграм`}/>}
            {newConnectionId && <QrCodeStep qrCode={qrCode}/>}
        </>
    );
};

export default TelegramConnectionSteps;
