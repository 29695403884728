import React, {useEffect, useState} from 'react';
import {useHeader} from '../../context/HeaderContext';
import {clientWsUserProfile} from "../../api/client-ws";

const Dashboard: React.FC = () => {
    const {setHeaderContent} = useHeader();

    const [fullName, setFullName] = useState<string>("");

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await clientWsUserProfile.load();
                setFullName(response.data.fullName);
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };
        fetchUserProfile();
    }, []);

    useEffect(() => {
        setHeaderContent('Добро пожаловать! ' + fullName);
    }, [setHeaderContent, fullName]);  // Отдельный useEffect для обновления заголовка


    return (
        <div>
            {/*<h2>Dashboard</h2>*/}
            {/* Содержимое страницы Dashboard */}
        </div>
    );
};

export default Dashboard;
