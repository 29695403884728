import React from 'react';

interface AvitoConnectionDescriptionProps {
    showAsList?: boolean;
}

const AvitoConnectionDescription: React.FC<AvitoConnectionDescriptionProps> = ({showAsList = false}) => {
    const content = (
        <li>Вас перенаправит на страницу авторизации Avito.</li>
    );

    return showAsList ? <ol className="list-decimal pl-5 space-y-2 text-lg">{content}</ol> : content;
};

export default AvitoConnectionDescription;
