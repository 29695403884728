import React, {useCallback} from 'react';
import {clientWsContentUpload} from "../../api/client-ws";
import {FaCloudArrowUp} from "react-icons/fa6";
import {AppDispatch, RootState} from "../../redux";
import {useDispatch, useSelector} from "react-redux";
import {pushAttachment} from "../../redux/slices/inbox-slice";
import {LIMITS} from "../types/types";
import ThemedButton from "./ThemedButton";

const UploadFileButton: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const attachments = useSelector((state: RootState) => state.inbox.attachments);
    const messages = useSelector((state: RootState) => state.inbox.messages);


    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            clientWsContentUpload.upload(formData)
                .then(response => {
                    dispatch(pushAttachment(response.data));
                })
                .catch(error => {
                    console.error('Ошибка при загрузке файла:', error);
                    // Здесь можно обработать ошибки
                });
        }
    };

    const getLastMessageChannelType = useCallback(() => {
        return messages.length > 0 ? messages[messages.length - 1].channelType : '';
    }, [messages]);

    return (
        <div className="flex items-center">
            <ThemedButton
                inverted={true}
                backgroundInverted={true}
                disabled={attachments?.length >= LIMITS.FILES}
                theme={getLastMessageChannelType() ?? ''}
                onClick={() => document.getElementById('fileInput')?.click()}
            >
                <FaCloudArrowUp/>
            </ThemedButton>
            <input
                id="fileInput"
                type="file"
                className="hidden"
                onChange={handleFileUpload}
            />
        </div>
    );
};

export default UploadFileButton;
