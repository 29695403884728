import React, {useEffect, useState} from 'react';
import Button, {ButtonColor} from "../atom/Button";

const DevelopmentBanner: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [showBanner, setShowBanner] = useState(true);

    useEffect(() => {
        const isProd = window.location.hostname.startsWith('prod');
        if (isProd) {
            setShowBanner(false);
        }
    }, []);

    const toggleBanner = () => {
        setIsOpen(!isOpen);
    };

    if (!showBanner) {
        return null;
    }

    return (
        <div
            className={`w-full bg-[#f8fcfe] text-slate-800 items-center justify-center text-center p-1 cursor-pointer`}
            onClick={toggleBanner}
        >
            <Button buttonColor={ButtonColor.TRANSPARENT} title={`Внимание`} onClick={toggleBanner}
                    customClass={"px-2 py-0"}/>
            <div
                className={`overflow-hidden transition-all duration-500 ease-in-out transform ${isOpen ? 'max-h-screen opacity-100 translate-y-0' : 'max-h-0 opacity-0 translate-y-4'}`}
            >
                <div className="mt-1 p-4 border-t border-blue-100">
                    Вы находитесь на тестовом демо-сайте нашего сервиса. Здесь вы можете попробовать все функции и
                    понять, как это будет работать для вас. Пожалуйста, учтите, что это тестовая среда, и она
                    предназначена для отладки и проверки функционала. Ваши сообщения и данные могут не сохраняться, так
                    как база данных в этом режиме может меняться.
                    Если вам все понравится напиште нам <a className={`text-blue-800`}
                                                           href="mailto:support@chatholder.ru&body=?subject=Перенос+учетной+записи+на+основной+сайт">support@chatholder.ru</a>,
                    мы с радостью перенесем вас на основную версию нашего сервиса, где все
                    будет работать стабильно и безопасно.
                    Спасибо за понимание и приятного тестирования!<br/>
                </div>
            </div>
        </div>
    );
};

export default DevelopmentBanner;
