import React, {useEffect} from 'react';
import {useHeader} from '../../context/HeaderContext';
import ComingSoon from '../atom/ComingSoon';
import ContentWrapper from "../atom/ContentWrapper";

const Contacts: React.FC = () => {
    const {setHeaderContent} = useHeader();

    useEffect(() => {
        setHeaderContent('Контакты');
    }, [setHeaderContent]);

    return (
        <ContentWrapper>
            <div className="flex h-screen overflow-hidden justify-center items-center">
                <ComingSoon/>
            </div>
        </ContentWrapper>
    );
};

export default Contacts;
