import React from 'react';
import {AppDispatch, RootState} from "../../../redux";
import {useDispatch, useSelector} from "react-redux";
import {setShowUnread} from "../../../redux/slices/inbox-slice";
import {FaLocationCrosshairs} from "react-icons/fa6";
import Button, {ButtonColor, ButtonSize} from "../../atom/Button";

const FilterOnlyUnreadChats: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const showUnread = useSelector((state: RootState) => state.inbox.showUnread);

    const handleClick = () => {
        dispatch(setShowUnread(!showUnread));
    }

    return (
        <>
            {showUnread
                ? <Button icon={<FaLocationCrosshairs/>} onClick={handleClick}
                          buttonSize={ButtonSize.MEDIUM}
                          customClass={"bg-slate-100 text-orange-600 hover:text-orange-600"}/>
                : <Button icon={<FaLocationCrosshairs/>} onClick={handleClick}
                          buttonColor={ButtonColor.SLATE} buttonSize={ButtonSize.MEDIUM}/>
            }
        </>
    );
};

export default FilterOnlyUnreadChats;
