import React from 'react';
import Button, {ButtonColor, ButtonSize} from "../../atom/Button";
import {FaEllipsisV} from "react-icons/fa";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../redux";
import {ModalType} from "../../types/types";
import {setTypeOfModalToBeShown} from "../../../redux/slices/common-slice";
import MarkChatAsReadButton from "./MarkChatAsReadButton";

const MessagesToolbar: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const selectedChat = useSelector((state: RootState) => state.inbox.selectedChat);
    const handleMenuClick = () => {
        dispatch(setTypeOfModalToBeShown(ModalType.InboxSettings))
    }


    return (
        <div
            className="flex px-2 space-x-0.5 items-center justify-between border-b border-solid border-slate-300 h-16 w-full">
            <div className="flex-1 ml-2 overflow-hidden w-0 min-w-0">
                <div className="text font-medium text-slate-700 truncate" title={`${selectedChat?.chatTitle}`}>
                    {selectedChat?.chatTitle}
                </div>
            </div>
            <div className="flex">
                <MarkChatAsReadButton/>
                <Button icon={<FaEllipsisV/>} onClick={handleMenuClick} buttonColor={ButtonColor.TRANSPARENT}
                        buttonSize={ButtonSize.NORMAL}/>
            </div>
        </div>

    );
};

export default MessagesToolbar;
