import React, {useState} from "react";
import {useLocation} from "react-router-dom";
import {authWsInvitation} from "../../../api/auth-ws";
import {handleLogError} from "../../../api/base-api";
import SignupForm from "../../molecula/SignupForm";

const SignupForTeammate: React.FC = () => {
    const location = useLocation();
    const inviteCodeFromUrl = location.search.substring(1); // Извлекаем invite-код после `?`
    const [isInviteValid, setIsInviteValid] = useState(false);

    const validateInvite = async (inviteCode: string) => {
        try {
            await authWsInvitation.validateInvite(inviteCode);
            setIsInviteValid(true);
        } catch (error: any) {
            handleLogError(error);
        }
    };

    return <SignupForm inviteCode={inviteCodeFromUrl} isInviteValid={isInviteValid} onValidateInvite={validateInvite}/>;
};

export default SignupForTeammate;