import React from 'react';
import ConnectionsTableRow from './ConnectionsTableRow';
import {Connection, ModalType} from "../../types/types";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../redux";
import {FaCircleQuestion} from "react-icons/fa6";
import {setTypeOfModalToBeShown} from "../../../redux/slices/common-slice";


interface ConnectionsTableProps {
    onEdit: (connection: Connection) => void;
    onDelete: (connection: Connection) => void;
}

const ConnectionsTable: React.FC<ConnectionsTableProps> = ({onEdit, onDelete}) => {
    const dispatch: AppDispatch = useDispatch();
    const reduxConnections = useSelector((state: RootState) => state.connections.connections);


    const handleEdit = (connection: Connection) => {
        onEdit(connection);
    };

    const handleDelete = (connection: Connection) => {
        onDelete(connection);
    };

    const handleStatusInfo = () => {
        dispatch(setTypeOfModalToBeShown(ModalType.ConnectionStatus));
    }

    return (
        <table className="w-full border-collapse text-slate-800">
            <thead className="h-14 bg-slate-100 text-slate-500">
            <tr>
                <th className="py-2 px-4 w-16"></th>
                <th className="py-2 px-4 w-64">Название</th>
                <th className="py-2 px-4 w-24">Тип</th>
                <th className="py-2 px-4 w-24 flex">
                    <div>Статус</div>
                    <div className={"flex-1 text-xs pl-1 pt-1 cursor-pointer"}><FaCircleQuestion
                        onClick={handleStatusInfo}/></div>

                </th>
                <th className="py-2 px-4"></th>
                <th className="py-2 px-4 w-8"></th>
            </tr>
            </thead>
            <tbody>
            {reduxConnections.map((connection) => (
                <ConnectionsTableRow
                    key={connection.channelId}
                    connection={connection}
                    onDelete={handleDelete}
                    onEdit={handleEdit}
                />
            ))}
            </tbody>
        </table>
    );
};

export default ConnectionsTable;
