import React, {createContext, ReactNode, useContext, useState} from 'react';

// Определяем тип для контекста
interface HeaderContextProps {
    headerContent: string;
    setHeaderContent: React.Dispatch<React.SetStateAction<string>>;
}

// Создаем контекст с начальным значением undefined
const HeaderContext = createContext<HeaderContextProps | undefined>(undefined);

// Создаем провайдер для контекста
export const HeaderProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [headerContent, setHeaderContent] = useState<string>('Добро пожаловать!');

    return (
        <HeaderContext.Provider value={{headerContent, setHeaderContent}}>
            {children}
        </HeaderContext.Provider>
    );
};

// Хук для использования контекста
export const useHeader = (): HeaderContextProps => {
    const context = useContext(HeaderContext);
    if (!context) {
        throw new Error('useHeader must be used within a HeaderProvider');
    }
    return context;
};
