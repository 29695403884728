import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';

type TooltipProps = {
    children: React.ReactNode;
    text: string;
    color?: TooltipColor;
    customClass?: string;
    arrow?: boolean;
    delay?: number;
};

export enum TooltipColor {
    BLACK = 'bg-black text-white',
    SLATE = 'bg-slate-600 text-white',
    WHITE = 'bg-white text-black',
    RED = 'bg-red-600 text-white',
    GREEN = 'bg-green-600 text-white',
    BLUE = 'bg-blue-600 text-white',
}

const Tooltip: React.FC<TooltipProps> = ({
                                             children,
                                             text = '',
                                             color = TooltipColor.SLATE,
                                             customClass = '',
                                             arrow = false,
                                             delay = 500,
                                         }) => {
    const [visible, setVisible] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [position, setPosition] = useState<{ top: number; left: number; arrowLeft: number; arrowPosition: string }>({
        top: 0,
        left: 0,
        arrowLeft: 0,
        arrowPosition: 'bottom',
    });

    const tooltipRef = useRef<HTMLDivElement>(null);
    const childRef = useRef<HTMLDivElement>(null);
    const showTimerRef = useRef<NodeJS.Timeout | null>(null);

    const calculatePosition = () => {
        if (!tooltipRef.current || !childRef.current) return;

        const tooltipRect = tooltipRef.current.getBoundingClientRect();
        const childRect = childRef.current.getBoundingClientRect();

        let top = childRect.top - tooltipRect.height - 8;
        let left = childRect.left + (childRect.width - tooltipRect.width) / 2;
        let arrowLeft;
        let arrowPosition = 'bottom';

        arrowLeft = Math.min(
            Math.max((childRect.left + childRect.width / 2) - left - 8, 8),
            tooltipRect.width - 16
        );

        if (top < window.scrollY) {
            top = childRect.bottom + 8;
            arrowPosition = 'top';
        }

        if (left < 8) {
            left = 8;
            arrowLeft = Math.max((childRect.left + childRect.width / 2) - left - 4, 8);
        }
        if (left + tooltipRect.width > window.innerWidth) {
            left = window.innerWidth - tooltipRect.width - 8;
            arrowLeft = Math.min((childRect.left + childRect.width / 2) - left - 4, tooltipRect.width - 16);
        }

        setPosition({top, left, arrowLeft, arrowPosition});
    };

    const showTooltip = () => {
        setMounted(true);
    };

    const hideTooltip = () => {
        setVisible(false);
        setMounted(false);
        if (showTimerRef.current) {
            clearTimeout(showTimerRef.current);
        }
    };

    const handleMouseEnter = () => {
        showTimerRef.current = setTimeout(() => {
            showTooltip();
        }, delay);
    };

    const handleMouseLeave = () => {
        hideTooltip();
    };

    useEffect(() => {
        if (mounted) {
            calculatePosition();
            setVisible(true);
        }
    }, [mounted]);

    useEffect(() => {
        return () => {
            if (showTimerRef.current) {
                clearTimeout(showTimerRef.current);
            }
        };
    }, []);

    return (
        <div
            className="relative inline-block"
            ref={childRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {children}
            {mounted &&
                ReactDOM.createPortal(
                    <div
                        ref={tooltipRef}
                        className={`fixed z-50 p-2 rounded text-xs shadow-lg transition-opacity duration-200 ${color} ${customClass}`}
                        style={{
                            top: position.top,
                            left: position.left,
                            opacity: visible ? 1 : 0,
                            pointerEvents: 'none',
                        }}
                    >
                        {text}
                        {arrow && (
                            <div
                                className={`absolute w-3 h-3 ${color}`}
                                style={{
                                    transform: 'translateX(-50%) rotate(45deg)',
                                    top: position.arrowPosition === 'bottom' ? '100%-4px' : '-4px',
                                    left: `${position.arrowLeft}px`,
                                }}
                            />
                        )}
                    </div>,
                    document.body
                )}
        </div>
    );
};

export default Tooltip;
