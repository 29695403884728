import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from "../../../redux";
import {clientWsMessage} from "../../../api/client-ws";
import {pushMessages, setMessages} from "../../../redux/slices/inbox-slice";
import Message from './Message';
import {MessageInterface} from "../../types/types";

const Messages: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const messages = useSelector((state: RootState) => state.inbox.messages);
    const chatId = useSelector((state: RootState) => state.inbox.selectedChatId);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMoreMessages, setHasMoreMessages] = useState(true);

    useEffect(() => {
        if (chatId) {
            resetChatState();
            loadMessages(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatId]);

    const resetChatState = () => {
        dispatch(setMessages([]));
        setPage(0);
        setHasMoreMessages(true);
    };

    const loadMessages = async (isInitialLoad = false) => {

        if (loading || !chatId) return;

        setLoading(true);
        const currentPage = isInitialLoad ? 0 : page;

        try {
            const response = await clientWsMessage.find(chatId, currentPage, 10);
            if (response?.data?.content?.length) {
                const container = containerRef.current;

                if (container) {
                    const previousScrollHeight = container.scrollHeight;
                    const previousScrollTop = container.scrollTop;

                    dispatch(pushMessages(response.data.content));

                    // Используем requestAnimationFrame для предотвращения подергиваний
                    requestAnimationFrame(() => {
                        // Рассчитываем новую позицию скролла
                        const newScrollHeight = container.scrollHeight;
                        const addedHeight = newScrollHeight - previousScrollHeight;

                        container.scrollTop = previousScrollTop + addedHeight;
                    });

                    if (response.data.content.length < 10) {
                        setHasMoreMessages(false);
                    } else {
                        setPage(currentPage + 1);
                    }
                }
            } else {
                setHasMoreMessages(false);
            }
        } catch (error) {
            console.error("Ошибка при загрузке сообщений:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleScroll = () => {
        const container = containerRef.current;
        if (container && container.scrollTop === 0 && hasMoreMessages && !loading) {
            loadMessages();
        }
    };

    return (
        <div
            className="flex-1 overflow-y-auto p-4 space-y-4"
            ref={containerRef}
            onScroll={handleScroll}
        >
            {messages.map((message: MessageInterface) => (
                <Message key={message.messageId} message={message}/>
            ))}
            {loading && <div className="loading-indicator">Загрузка...</div>}
        </div>
    );
};

export default Messages;
